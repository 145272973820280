/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Typography,
  Grid,
  LinearProgress,
  Button,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  GET_PROMOTIONAL_CODES,
  UPDATE_PROMOTIONAL_CODE,
  CREATE_PROMOTIONAL_CODE,
} from '../gql/promotionalCodes';
import ErrorPage from './ErrorPage';
import { MainContext } from '../context/MainContext';
import { useMainStyles } from '../styles';
import { PromotionalCode } from '../components/promotionalCodes';
import { OneButtonAlert } from '../components/dialogs';
import { promotionalCodeHasEmptyField } from '../helpers/validators';
import { stringToMultiline } from "../helpers/strings";

const PromotionalCodes = () => {
  const classes = useMainStyles();
  const { store } = useContext(MainContext);
  const { data, loading, error, refetch } = useQuery(GET_PROMOTIONAL_CODES, {
    variables: { restaurantId: store.id },
  });
  const [updatePromotionalCode] = useMutation(UPDATE_PROMOTIONAL_CODE);
  const [createPromotionalCode] = useMutation(CREATE_PROMOTIONAL_CODE);
  const [promotionalCodes, setPromotionalCodes] = useState(null);
  const [formError, setFormError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (data) {
      const { promotionalCodes: dbPromotionalCodes } = data;
      setPromotionalCodes(
        [...dbPromotionalCodes]
          .sort((a, b) => a.created_at <= b.created_at ? 1 : -1)
          .map((promotionalCode) => {
            if (store.erpConfig && store.erpConfig.erp !== '') {
              return {
                ...promotionalCode,
                multipleCodes: promotionalCode.multipleCodes ? stringToMultiline(promotionalCode.multipleCodes.toString()) : [],
                items: promotionalCode.itemsJSON,
                categories: promotionalCode.categoriesJSON,
              };
            }
            return promotionalCode;
          }),
      );
    }
  }, [data]);

  const getPromise = (_promotionalCodes) => {
    const promises = [];
    _promotionalCodes.forEach((pc) => {
      const {
        id,
        code,
        multipleCodes,
        isNew,
        isActive,
        isStackable,
        isMultiple,
        isUnique,
        isNewUsersOnly,
        isLimited,
        type,
        rule,
        total,
        name,
        items,
        categories,
      } = pc;
      let variables = {
        code,
        isActive,
        isStackable,
        isMultiple,
        isUnique,
        isNewUsersOnly,
        isLimited,
        type,
        rule,
        name,
        id: isNew ? null : id,
        total: parseInt(total, 10),
        remaining: parseInt(total, 10),
        restaurant: store.id,
      };
      if (multipleCodes && typeof(multipleCodes) === 'string') {
        variables.multipleCodes = multipleCodes.trim().split(/\r?\n/);
      }
      // If user has an erp integrated, use JSON
      if (store.erpConfig && store.erpConfig.erp !== '') {
        variables = {
          ...variables,
          itemsJSON: items,
          categoriesJSON: categories,
        };
      } else {
        variables = {
          ...variables,
          items: items.map((item) => item.id),
          categories: categories.map((category) => category.id),
        };
      }
      if (isNew) {
        promises.push(createPromotionalCode({ variables }));
      } else {
        promises.push(updatePromotionalCode({ variables }));
      }
    });
    return promises;
  };

  const handleNewPromotionalCode = () => {
    const newPromotionalCodes = [...promotionalCodes];
    const currentNewCodes = newPromotionalCodes.filter((pc) => pc.isNew);
    const tempId = `nuevo ${currentNewCodes.length + 1}`;
    const newPromotionalCode = {
      id: tempId,
      isActive: false,
      isStackable: false,
      isUnique: false,
      isNewUsersOnly: false,
      isLimited: false,
      name: '',
      code: '',
      type: 'checkDiscount',
      rule: '20',
      categories: [],
      items: [],
      total: 100,
      isNew: true,
      created_at: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    };
    newPromotionalCodes.unshift(newPromotionalCode);
    setPromotionalCodes(newPromotionalCodes);
  };

  const handleRemovePromotionalCode = (id) => {
    const newPromotionalCodes = [...promotionalCodes].filter((pc) => pc.id !== id);
    setPromotionalCodes(newPromotionalCodes);
  };

  const handlePromotionalCodeChange = (promotionalCode, i) => {
    const newPromotionalCodes = [...promotionalCodes];
    newPromotionalCodes[i] = { ...promotionalCode, modified: true };
    setPromotionalCodes(newPromotionalCodes);
  };

  const handleSaveChanges = async () => {
    const modifiedPromotionalCodes = promotionalCodes.filter((pc) => pc.modified && !pc.isNew);
    const createdPromotionalCodes = promotionalCodes.filter((pc) => pc.isNew);
    if (modifiedPromotionalCodes.length <= 0 && createdPromotionalCodes.length <= 0) {
      setFormError('No realizaste cambios');
      return;
    }
    setSaveLoading(true);
    let scopeFormError = '';
    [...modifiedPromotionalCodes, ...createdPromotionalCodes].forEach((pc) => {
      if (promotionalCodeHasEmptyField(pc)) {
        scopeFormError = `El código ${pc.id} tiene un campo vacío`;
      }
    });
    if (scopeFormError) {
      setFormError(scopeFormError);
      setSaveLoading(false);
      return;
    }
    const createPromises = getPromise(createdPromotionalCodes);
    const updatePromises = getPromise(modifiedPromotionalCodes);
    await Promise.all([...updatePromises, ...createPromises]);
    setFormError('');
    setSaveLoading(false);
    setAlertOpen(true);
    refetch();
  };

  if (error) return <ErrorPage />;
  if (loading || !promotionalCodes) return <LinearProgress />;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" className={classes.header}>Códigos promocionales</Typography>
      </Grid>
      {promotionalCodes.length === 0 && (
        <Typography variant="h6" gutterBottom>Crea tu primer código promocional presionando el siguiente botón</Typography>
      )}
      <Grid item xs={12}>
        <Hidden smDown>
          <Button
            onClick={handleNewPromotionalCode}
            color="primary"
            startIcon={<Add />}
            variant="contained"
          >
            Nuevo código
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button
            onClick={handleNewPromotionalCode}
            color="primary"
            startIcon={<Add />}
          >
            Nuevo código
          </Button>
        </Hidden>
      </Grid>
      <Grid item xs={12}>
        {[...promotionalCodes]
          .map((promotionalCode, i) => (
            <div key={promotionalCode.id} style={{ borderBottom: '1px solid #DDDDDD', padding: '2rem 0 1rem' }}>
              <PromotionalCode
                promotionalCode={promotionalCode}
                liftPromotionalCode={
                  (_promotionalCode) => handlePromotionalCodeChange(_promotionalCode, i)
                }
                handleRemove={
                  promotionalCode.isNew
                    ? () => handleRemovePromotionalCode(promotionalCode.id)
                    : () => { }
                }
              />
            </div>
          ))}
      </Grid>
      <Grid container direction="column" alignItems="flex-end" className={classes.saveButtonContainer}>
        <Grid item xs={12} md={3} className={classes.saveButtonItem}>
          {Boolean(formError) && (
            <Typography
              component="div"
              className={classes.subSection}
              align="center"
              color="error"
              variant="caption"
              style={{ margin: 0 }}
            >
              {formError}
            </Typography>
          )}
          <Button
            onClick={handleSaveChanges}
            color="primary"
            disabled={saveLoading}
            variant="contained"
            fullWidth
          >
            {saveLoading ? <CircularProgress size={16} /> : 'Guardar Cambios'}
          </Button>
        </Grid>
      </Grid>
      <OneButtonAlert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title="Cambios realizados con éxito"
      />
    </Grid>
  );
};

export default PromotionalCodes;
