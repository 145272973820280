import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { UniqueFileUpload } from '../utils';

const InStoreMenus = ({ inStoreMenus = [], handleInStoreMenusChange }) => {
  const [currentInStoreMenus, setCurrentInStoreMenus] = useState(inStoreMenus);
  useEffect(() => setCurrentInStoreMenus(inStoreMenus || []), [inStoreMenus])

  const handleMenuNameChange = (event, inStoreMenu) => {
    const { target: { value } } = event;
    const newInStoreMenu = { ...inStoreMenu };
    newInStoreMenu.name = value;
    newInStoreMenu.updatedName = !inStoreMenu.new;
    const newInStoreMenus = [...currentInStoreMenus];
    const inStoreMenuIndex = newInStoreMenus.findIndex((_inStoreMenu) => _inStoreMenu.id === inStoreMenu.id);
    newInStoreMenus[inStoreMenuIndex] = newInStoreMenu;
    setCurrentInStoreMenus(newInStoreMenus);
    handleInStoreMenusChange(newInStoreMenus);
  }

  const handleFileChange = (file, inStoreMenu) => {
    const newInStoreMenu = { ...inStoreMenu, file, updatedFile: !inStoreMenu.new };
    let newInStoreMenus = [...currentInStoreMenus];
    const inStoreMenuIndex = newInStoreMenus.findIndex((_inStoreMenu) => _inStoreMenu.id === inStoreMenu.id);
    if (!file) {
      newInStoreMenus = [
        ...newInStoreMenus.slice(0, inStoreMenuIndex),
        ...newInStoreMenus.slice(inStoreMenuIndex + 1, newInStoreMenus.length)
      ];
    } else {
      newInStoreMenus[inStoreMenuIndex] = newInStoreMenu;
    }
    setCurrentInStoreMenus(newInStoreMenus);
    handleInStoreMenusChange(newInStoreMenus);
  }

  const handleNewMenu = () => {
    const newInStoreMenus = [...currentInStoreMenus];
    newInStoreMenus.push({ id: Math.random().toFixed(8).toString(), new: true });
    setCurrentInStoreMenus(newInStoreMenus);
  }

  return (
    <>
      <Typography gutterBottom><b>Cartas para consumir en el local</b></Typography>
      {currentInStoreMenus.length > 0 ? currentInStoreMenus.map((inStoreMenu) => {
        const formError = inStoreMenu.file && !inStoreMenu.name
        return (
        <div style={{ marginBottom: '2rem'}} key={inStoreMenu.id}>
          <Typography>Nombre de la carta</Typography>
          <TextField
            style={{ marginBottom: '0.5rem' }}
            value={inStoreMenu.name || ''}
            onChange={(e) => handleMenuNameChange(e, inStoreMenu)}
            variant="outlined"
            fullWidth
            error={formError}
            helperText={formError && "La carta debe tener un nombre"}
          />
          <UniqueFileUpload
            label="Archivo pdf de la carta"
            accept="application/pdf"
            onChange={(file) => handleFileChange(file, inStoreMenu)}
            initialValue={{ ...inStoreMenu.file, ...inStoreMenu }}
            placeholder="Clickea para seleccionar el archivo"
          />
        </div>
      )}) : (
        <Typography gutterBottom> No has agregado cartas para consumo en el local</Typography>
      )}
      <Button
        color="primary"
        onClick={handleNewMenu}
        startIcon={<Add />}
        size="small"
      >
        Agregar otra carta
      </Button>
    </>
  )
}

export default InStoreMenus;
