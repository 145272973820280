import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const MainContext = createContext({
  user: null,
  store: null,
});

export const MainContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState({
    user: JSON.parse(localStorage.getItem('user')),
    store: JSON.parse(localStorage.getItem('store')),
  });

  const {
    user,
    store,
  } = state;

  return (
    <MainContext.Provider value={{
      user,
      store,
      setUser: (newUser) => {
        setState((oldState) => ({ ...oldState, user: newUser }));
        localStorage.setItem('user', JSON.stringify(newUser));
      },
      setStore: (newStore) => {
        setState((oldState) => ({ ...oldState, store: newStore }));
        localStorage.setItem('store', JSON.stringify(newStore));
      },
    }}
    >
      {children}
    </MainContext.Provider>
  );
};

MainContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
