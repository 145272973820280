import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Grid, LinearProgress, useMediaQuery, useTheme,
} from '@material-ui/core';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { MainContext } from '../../context/MainContext';
import { GET_STORE } from '../../gql/store';
import { useLazyQuery } from '@apollo/client';

const Layout = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const [getStore, { loading, error, data }] = useLazyQuery(GET_STORE);
  const { user, setUser, store, setStore } = useContext(MainContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setStore(data.restaurant);
      window.location.reload();
    }
  }, [data])

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const logout = () => {
    setUser(null);
    setStore(null);
    history.replace('/');
  };

  const handleStoreChange = async (newStore) => {
    getStore({ variables: { id: newStore.id } });
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header
          toggleDrawer={toggleDrawer}
          logout={logout}
          user={user}
          store={store}
          handleStoreChange={handleStoreChange}
          setStore={setStore}
        />
      </Grid>
      <Grid item lg={2}>
        <LeftNavigation
          onClose={() => setDrawerOpen(false)}
          logout={logout}
          open={drawerOpen}
          anchor={isSmall ? 'right' : 'left'}
          variant={isSmall ? 'temporary' : 'permanent'}
        />
      </Grid>
      {loading ? <LinearProgress /> : <Grid item lg={10}>{children}</Grid>}
    </Grid>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
