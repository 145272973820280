import { gql } from '@apollo/client';

export const MULTIPLE_UPLOAD = gql`
  mutation MultipleUpload($files: [Upload]!) {
    multipleUpload(files: $files) {
      id
    }
  }
`;

export const UNIQUE_UPLOAD = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      id
    }
  }
`;
