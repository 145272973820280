/* eslint-disable no-useless-escape */
export const validEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const formErrorToSpanish = {
  'Auth.form.error.invalid': 'Usuario o contraseña inválidos',
};

export const promotionalCodeHasEmptyField = (promotionalCode) => {
  const {
    multipleCodes,
    code,
    type,
    rule,
    total,
    name,
    items,
    categories,
  } = promotionalCode;

  let dataType = 'all';
  if (type.indexOf('item') !== -1) dataType = 'items';
  if (type.indexOf('category') !== -1) dataType = 'categories';

  const noCode = code === '' && multipleCodes.length === 0;

  if (noCode || type === '' || rule === '' || total === '' || name === '') return true;
  if (dataType === 'items' && items.length <= 0) return true;
  if (dataType === 'categories' && categories.length <= 0) return true;
  return false;
};

export const deliveryZoneHasEmptyField = (deliveryZone) => {
  const {
    name,
    delivery_cost: deliveryCost,
    minimum_order: minimumOrder,
    delivery_times: deliveryTimes,
  } = deliveryZone;
  if (name === '' || deliveryCost === '' || minimumOrder === '') return true;
  let hasEmptyHours = false;
  deliveryTimes.forEach((dt) => {
    if (dt.hours_and_time) {
      dt.hours_and_time.forEach((ht) => {
        if (ht.start === '' || ht.end === '' || ht.time === '') hasEmptyHours = true;
      });
    }
  });
  if (hasEmptyHours) return true;
  return false;
};
