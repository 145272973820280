import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';

const OneButtonAlert = ({ open, onClose, message, title }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
      >
        Entendido
      </Button>
    </DialogActions>
  </Dialog>
);

OneButtonAlert.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

OneButtonAlert.defaultProps = {
  message: '',
};

export default OneButtonAlert;
