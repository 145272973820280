/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_SALES_DATA = gql`
  query GetSalesData($storeId: ID!){
    getSalesData(restaurantId: $storeId)
  }
`;

export const GET_USERS_DATA = gql`
  query GetUsersData($storeId: ID!){
    getUsersData(restaurantId: $storeId)
  }
`;
