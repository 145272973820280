import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { days } from '../../constants/dateTime';
import { translateDay } from '../../helpers/i18';
import { capitalize } from '../../helpers/strings';

const DaySelect = ({ currentSelection, doNotInclude, onChange }) => (
  <Select
    onChange={(event) => onChange(event.target.value)}
    value={currentSelection}
    variant="outlined"
    style={{ minWidth: 100 }}
  >
    {days.filter((day) => !doNotInclude.includes(day)).map((day) => (
      <MenuItem key={day} value={day}>{capitalize(translateDay(day, 'ES'))}</MenuItem>
    ))}
  </Select>
);

DaySelect.propTypes = {
  currentSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  doNotInclude: PropTypes.arrayOf(PropTypes.string),
};

DaySelect.defaultProps = {
  currentSelection: '',
  doNotInclude: ['none'],
};

export default DaySelect;
