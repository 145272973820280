import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { translatePromotionalCodeType } from '../../helpers/i18';

const codeTypes = [
  'checkDiscount',
  'categoryDiscount',
  'itemDiscount',
  'checkYxZ',
  'categoryYxZ',
  'itemYxZ',
];

const PromotionalCodeTypeSelect = ({ currentSelection, onChange }) => (
  <Select
    onChange={onChange}
    value={currentSelection}
    variant="outlined"
    fullWidth

  >
    {codeTypes.map((type) => (
      <MenuItem key={type} value={type}>{translatePromotionalCodeType(type, 'ES')}</MenuItem>
    ))}
  </Select>
);

PromotionalCodeTypeSelect.propTypes = {
  currentSelection: PropTypes.oneOf(codeTypes),
  onChange: PropTypes.func.isRequired,
};

PromotionalCodeTypeSelect.defaultProps = {
  currentSelection: 'checkDiscount',
};

export default PromotionalCodeTypeSelect;
