/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useMainStyles = makeStyles((theme) => ({
  container: {
    padding: '2% 5% 5%',
    [theme.breakpoints.down('md')]: {
      padding: '5% 5%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1rem 4rem',
    },
  },
  header: {
    marginBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
    },
  },
  leftSection: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '5%',
    },
  },
  rightSection: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '5%',
    },
  },
  section: {
    marginBottom: '2rem',
  },
  subSection: {
    marginBottom: '1rem',
  },
  subSubSection: {
    marginBottom: '0.5rem',
  },
  deleteButton: {
    marginLeft: '3%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '7%',
    },
  },
  saveButtonContainer: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    '-webkit-box-shadow': '0px -1px 25px 3px rgba(181,181,181,0.6)',
    '-moz-box-shadow': '0px -1px 25px 3px rgba(181,181,181,0.6)',
    boxShadow: '0px -1px 25px 3px rgba(181,181,181,0.6)',
  },
  saveButtonItem: {
    width: '100%',
    padding: '3%',
    [theme.breakpoints.up('md')]: {
      padding: 7,
      paddingRight: '10%',
    },
  },
}));
