import React, { useContext, useEffect, useState } from 'react';
import {
  LinearProgress,
  Paper,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import GetAppIcon from '@material-ui/icons/GetApp';
import { GET_USERS_DATA } from '../gql/analytics';
import { MainContext } from '../context/MainContext';
import ErrorPage from './ErrorPage';
import { EnhancedTable } from '../components/table';
import { translateUsersType } from '../helpers/i18';
import { getUsersXlsxBase64 } from '../apiCalls/strapi';

const useStyles = makeStyles({
  container: {
    padding: '5% 4%',
  },
  paper: {
    padding: '1rem',
  },
  tableTitle: {
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  paginationSelect: {
    paddingRight: '24px !important',
  },
});

const headCells = [
  { id: 'user_id', numeric: false, label: 'Id' },
  { id: 'first_name', numeric: false, label: 'Nombre' },
  { id: 'last_name', numeric: false, label: 'Apellido' },
  { id: 'email', numeric: false, label: 'Email' },
  { id: 'phone_number', numeric: false, label: 'Teléfono' },
  { id: 'first_order_date', numeric: false, label: 'Primera compra' },
  { id: 'last_order_date', numeric: false, label: 'Última compra' },
  { id: 'amount_spent_text', numeric: true, label: 'Compras totales' },
];

const UsersAnalytics = () => {
  const classes = useStyles();
  const { store } = useContext(MainContext);
  const { data, error } = useQuery(GET_USERS_DATA, {
    variables: { storeId: store.id },
  });
  const [usersData, setUsersData] = useState(null);
  const [selectedUsersType, setSelectedUsersType] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingXlsx, setLoadingXlsx] = useState(false);

  useEffect(() => {
    if (data) {
      setUsersData(data.getUsersData);
      setLoading(false);
    }
  }, [data]);

  const getUsersXlsx = async () => {
    setLoadingXlsx(true);
    getUsersXlsxBase64(store.id)
      .then(async (response) => {
        const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        const href = mediaType + response.data;
        const theLink = document.createElement('a');
        theLink.href = href;
        theLink.download = `clientes_${store.name}_${moment().format('YYYYMMDDHH:ss')}.xlsx`;
        document.body.appendChild(theLink);
        theLink.click();
        document.body.removeChild(theLink);
        setLoadingXlsx(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingXlsx(false);
      });
  };

  if (loading) return <LinearProgress />;
  if (error) return <ErrorPage />;

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {['totalUsers', 'newUsers', 'activeUsers', 'lostUsers'].map((usersType) => (
            <Grid item xs={6} md={3} key={usersType}>
              <Paper className={classes.paper}>
                <Typography variant="h6">{translateUsersType(usersType, 'ES')}</Typography>
                <Typography variant="h2">{usersData[usersType].length}</Typography>
                <Button color="primary" onClick={() => setSelectedUsersType(usersType)}>Ver detalle</Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {Boolean(selectedUsersType) && (
        <Grid item xs={12}>
          <Grid container style={{ marginBottom: '10px' }}>
            <Grid item xs={9}>
              <Typography variant="h6">{translateUsersType(selectedUsersType, 'ES')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={() => getUsersXlsx()}
                className={classes.button}
                startIcon={loadingXlsx ? <CircularProgress size={24} className={classes.buttonProgress} /> : <GetAppIcon />}
                fullWidth
                disabled={loadingXlsx}
              >
                Descargar listado
              </Button>
            </Grid>
          </Grid>
          <EnhancedTable headCells={headCells} rows={usersData[selectedUsersType]} />
        </Grid>
      )}
    </Grid>
  );
};

export default UsersAnalytics;
