import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import { CANCEL_UBER_DELIVERY } from '../../gql/uberDeliveries';
import moment from 'moment';

const statusToSpanish = {
  pending: 'Asignando repartidor',
  pickup: 'Repartidor en camino a retirar',
  pickup_complete: 'Pedido retirado',
  dropoff: 'Repartidor en camino a entregar',
  delivered: 'Entregado',
  canceled: 'Cancelado',
  returned: 'Devuelto',
  ongoing: 'En curso',
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 10
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    height: 40
  },
  clientContainer: {
    display: 'flex',
    direction: 'column',
  }
}));

const Card = ({ uberDelivery, refetch }) => {
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelUberDelivery, { data }] = useMutation(CANCEL_UBER_DELIVERY);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const {
    id,
    dropoff: {
      name,
      phone_number,
      address,
      notes,
    },
    pickup_eta,
    status,
    courier,
    delivery_id,
    uuid,
  } = uberDelivery;
  const classes = useStyles();

  const handleCancelDelivery = async () => {
    setConfirmDialogOpen(false);
    setCancelLoading(true);
    await cancelUberDelivery({
      variables: {
        uberId: delivery_id,
      },
    })
    setCancelLoading(false);
  }

  useEffect(() => {
    if (data && data.cancelUberDelivery && data.cancelUberDelivery.id) {
      setSuccessDialogOpen(true);
    }
  }, [data])

  const handleCloseDialog = () => {
    setSuccessDialogOpen(false);
    refetch();
  }

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" justifyContent="space-between" style={{ marginBottom: '0.5rem' }}>
        <Typography>Pedido {id}: {statusToSpanish[status]}</Typography>
        <Typography>Identificador pedido uber: {uuid}</Typography>
        {pickup_eta && (
          <Typography>Hora estimada de retiro: {moment(pickup_eta).format('HH:mm DD[/]MM[/]YYYY')}</Typography>
        )}
      </Grid>
      <Grid container className={classes.clientContainer}>
        <Grid item xs={6}>
          <Typography><strong>Cliente</strong></Typography>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="body2">{phone_number}</Typography>
          <Typography variant="body2">{address}</Typography>
          {notes && <Typography variant="caption">Notas: {notes}</Typography>}
        </Grid>
        {courier && (
          <Grid item xs={6}>
            <Typography><strong>Repartidor</strong></Typography>
            <Typography variant="body2">{courier.name}</Typography>
            <Typography variant="body2">{courier.phone_number}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container direction="column" alignItems="flex-end">
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={() => setConfirmDialogOpen(true)}
          disabled={cancelLoading}
          startIcon={cancelLoading ? <CircularProgress size={14} /> : null}
        >
          Cancelar pedido
        </Button>
        {data?.cancelUberDelivery.__typename === "GraphqlError" && (
          <Typography color="error" variant="caption">{data?.cancelUberDelivery.message}</Typography>
        )}
      </Grid>
      <Dialog
        open={successDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogContent>Pedido cancelado con éxito</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Entendido</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>¿Quieres cancelar el pedido?</DialogTitle>
        <DialogContent>Este proceso es irreversible y el cliente no recibirá su pedido</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelivery}>Cancelar pedido</Button>
          <Button onClick={() => setConfirmDialogOpen(false)}>Volver</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Card