import { gql } from '@apollo/client';

export const GET_DELIVERY_ZONES = gql`
  query GetPromotionalCodes(
    $restaurantId: ID!
  ) {
    zones(where: { restaurant: $restaurantId }) {
      id
      name
      delivery_cost
      radius
      lat
      lng
      minimum_order
      isActive
      delivery_times {
        id
        hours_and_time {
          id
          start
          end
          time
        }
        day
      }
      created_at
    }
  }
`;

export const CREATE_DELIVERY_ZONE = gql`
  mutation CreateDeliveryZone(
    $name: String
    $delivery_cost: Float
    $radius: Int
    $lat: Float
    $lng: Float
    $minimum_order: Float
    $isActive: Boolean
    $delivery_times: [ID]
    $restaurant: ID
  ) {
    createZone(
      input: {
        data: {
          name: $name,
          delivery_cost: $delivery_cost,
          radius: $radius,
          lat: $lat,
          lng: $lng,
          minimum_order: $minimum_order,
          isActive: $isActive,
          delivery_times: $delivery_times
          restaurant: $restaurant,
        }
      }
    ) {
      zone {
        id
      }
    }
  }
`;

export const UPDATE_DELIVERY_ZONE = gql`
  mutation UpdateDeliveryZone(
    $id: ID!
    $name: String
    $delivery_cost: Float
    $radius: Int
    $lat: Float
    $lng: Float
    $minimum_order: Float
    $isActive: Boolean
    $delivery_times: [ID]
  ) {
    updateZone(
      input: {
        where: { id: $id },
        data: {
          name: $name,
          delivery_cost: $delivery_cost,
          radius: $radius,
          lat: $lat,
          lng: $lng,
          minimum_order: $minimum_order,
          isActive: $isActive,
          delivery_times: $delivery_times
        }
      }
    ) {
      zone {
        id
      }
    }
  }
`;

export const DELETE_DELIVERY_ZONE = gql`
  mutation DeleteDeliveryZone(
    $id: ID!
  ) {
    deleteZone(input: { where: { id: $id } }) {
      zone {
        id
      }
    }
  }
`;

export const CREATE_DELIVERY_TIME = gql`
  mutation CreateDeliveryTime(
    $hours_and_time: [ComponentMainDayInput]
    $day: ENUM_DELIVERYTIME_DAY
  ) {
    createDeliveryTime(
      input: {
        data: {
          hours_and_time: $hours_and_time,
          day: $day,
        }
      }
    ) {
      deliveryTime {
        id
      }
    }
  }
`;

export const UPDATE_DELIVERY_TIME = gql`
  mutation UpdateDeliveryTime(
    $id: ID!
    $hours_and_time: [editComponentMainDayInput]
    $day: ENUM_DELIVERYTIME_DAY
  ) {
    updateDeliveryTime(
      input: {
        where: { id: $id },
        data: {
          hours_and_time: $hours_and_time,
          day: $day,
        }
      }
    ) {
      deliveryTime {
        id
      }
    }
  }
`;
