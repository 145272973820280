import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { formatMoney } from '../../helpers/money';

const SalesRanking = ({ values }) => (
  <>
    {values.map((value, i) => (
      <Grid key={value.name} container justify="space-between">
        <Typography>{i + 1}. {value.name}</Typography>
        <Typography>${formatMoney(value.sales)}</Typography>
      </Grid>
    ))}
  </>
);

SalesRanking.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    sales: PropTypes.number.isRequired,
  })).isRequired,
};

export default SalesRanking;
