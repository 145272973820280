/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { host } from '../config';

export const login = (email, password) =>
  axios.post(`${host}/auth/local`, {
    password,
    identifier: email,
  });

export const getUsersXlsxBase64 = (restaurantId) =>
  axios.get(`${host}/restaurants/${restaurantId}/usersXlsx`);

export const getOrdersByPromotionalCodeXlsxBase64 = (codeId) =>
  axios.get(`${host}/orders-by-promotional-code/${codeId}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).jwt}`,
    },
  });
