import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Switch,
  TextField,
  Grid,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Delete, GetApp } from '@material-ui/icons';
import { useMainStyles } from '../../styles';
import {
  PromotionalCodeTypeSelect,
  PromotionalCodeRuleSelect,
  PromotionalCodeAffectedSelect,
} from '../inputs';
import { getOrdersByPromotionalCodeXlsxBase64 } from '../../apiCalls/strapi';
import { stringToMultiline } from "../../helpers/strings";

const PromotionalCode = ({
  promotionalCode,
  liftPromotionalCode,
  handleRemove,
}) => {
  const [_promotionalCode, setPromotionalCode] = useState(promotionalCode);
  const [loadingXlsx, setLoadingXlsx] = useState(false);
  const classes = useMainStyles();
  const {
    id,
    isNew,
    name,
    code,
    multipleCodes,
    type,
    rule,
    total,
    isActive,
    isStackable,
    isUnique,
    isNewUsersOnly,
    isLimited,
    isMultiple,
    items,
    categories,
  } = _promotionalCode;

  const handleTextChange = (e) => {
    const { name: _name, value } = e.target;
    const newPromotionalCode = { ..._promotionalCode, [_name]: value };
    setPromotionalCode(newPromotionalCode);
    liftPromotionalCode(newPromotionalCode);
  };

  const handleBooleanChange = (e) => {
    const { name: _name, checked } = e.target;
    const newPromotionalCode = { ..._promotionalCode, [_name]: checked };
    setPromotionalCode(newPromotionalCode);
    liftPromotionalCode(newPromotionalCode);
  };

  const handleSelectChange = (e, _name) => {
    const newPromotionalCode = { ..._promotionalCode, [_name]: e.target.value };
    setPromotionalCode(newPromotionalCode);
    liftPromotionalCode(newPromotionalCode);
  };

  const handleSelectMultipleChange = (values, _name) => {
    const newPromotionalCode = { ..._promotionalCode, [_name]: values };
    setPromotionalCode(newPromotionalCode);
    liftPromotionalCode(newPromotionalCode);
  };

  const handleSalesDownload = () => {
    setLoadingXlsx(true);
    getOrdersByPromotionalCodeXlsxBase64(id).then((response) => {
      const mediaType =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
      const href = mediaType + response.data;
      const theLink = document.createElement('a');
      theLink.href = href;
      theLink.download = `Ventas código ${name}.xlsx`;
      document.body.appendChild(theLink);
      theLink.click();
      document.body.removeChild(theLink);
      setLoadingXlsx(false);
    });
  }

  let dataType = 'all';
  let currentData = [];
  if (type.indexOf("item") !== -1) {
    dataType = "items";
    currentData = items;
  }
  if (type.indexOf("category") !== -1) {
    dataType = "categories";
    currentData = categories;
  }

  return (
    <>
      <Grid container alignItems="center">
        <Typography>
          <b>Código {id}</b>
        </Typography>
        {isNew && (
          <IconButton onClick={handleRemove} style={{ marginLeft: "1rem" }}>
            <Delete color="error" />
          </IconButton>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="space-between">
            <Typography>{isActive ? "Activo" : "Inactivo"}</Typography>
            <Switch
              checked={isActive}
              name="isActive"
              onChange={handleBooleanChange}
              color="primary"
              inputProps={{ "aria-label": "Switch código activo" }}
            />
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Typography>
              {isStackable ? 'Acumulable' : 'No acumulable'}
            </Typography>
            <Switch
              checked={isStackable}
              name="isStackable"
              onChange={handleBooleanChange}
              color="primary"
              inputProps={{ "aria-label": "Switch código acumulable" }}
            />
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Typography>
              {isNewUsersOnly ? 'Solo usuarios nuevos' : 'Todos los usuarios'}
            </Typography>
            <Switch
              checked={isNewUsersOnly}
              name="isNewUsersOnly"
              onChange={handleBooleanChange}
              color="primary"
              inputProps={{ "aria-label": "Switch código solo usuarios nuevos" }}
            />
          </Grid>
          {!isNewUsersOnly && (
            <Grid container alignItems="center" justify="space-between">
              <Typography>
                {isUnique ? 'Uso único' : 'Usos ilimitados'}
              </Typography>
              <Switch
                checked={isUnique}
                name="isUnique"
                onChange={handleBooleanChange}
                color="primary"
                inputProps={{ "aria-label": "Switch código limite de usos por usuario" }}
              />
            </Grid>
          )}
          <Grid container alignItems="center" justify="space-between">
            <Typography>
              {isLimited ? 'Cupos limitados' : 'Cupos ilimitados'}
            </Typography>
            <Switch
              checked={isLimited}
              name="isLimited"
              onChange={handleBooleanChange}
              color="primary"
              inputProps={{ "aria-label": "Switch código cupos limitados" }}
            />
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Typography>
              {isMultiple ? "Códigos múltiples" : "Código único"}
            </Typography>
            <Switch
              checked={isMultiple}
              name="isMultiple"
              onChange={handleBooleanChange}
              color="primary"
              inputProps={{ "aria-label": "Switch código códigos múltiples" }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.subSection}>
              <Typography>Nombre</Typography>
              <TextField
                name="name"
                value={name}
                onChange={handleTextChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            {isMultiple ? (
              <Grid item xs={12} className={classes.subSection}>
                <Typography>Códigos (uno en cada línea)</Typography>
                <TextField
                  name="multipleCodes"
                  value={multipleCodes}
                  multiline
                  placeholder="Ingresa un código en cada línea"
                  onBlur={(e) => {
                    e.target.value = stringToMultiline(e.target.value);
                    handleTextChange(e);
                  }}
                  onChange={(e) => {
                    e.target.value = stringToMultiline(e.target.value);
                    handleTextChange(e);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} className={classes.subSection}>
                <Typography>Código</Typography>
                <TextField
                  name="code"
                  value={code}
                  onChange={handleTextChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={8} className={classes.subSection}>
              <Typography>Tipo</Typography>
              <PromotionalCodeTypeSelect
                currentSelection={type}
                onChange={(e) => handleSelectChange(e, "type")}
              />
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.subSection}
              style={{ paddingLeft: '1rem' }}
            >
              <Typography>Regla</Typography>
              <PromotionalCodeRuleSelect
                currentSelection={rule}
                selectedType={type}
                onChange={(e) => handleSelectChange(e, "rule")}
              />
            </Grid>
            <Grid item xs={12} className={classes.subSection}>
              <PromotionalCodeAffectedSelect
                type={dataType}
                currentSelection={currentData}
                onChange={(values) =>
                  handleSelectMultipleChange(values, dataType)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {isLimited && (
          <Grid item xs={12} md={1}>
            <Grid container>
              <Grid item xs={12} className={classes.subSection}>
                <Typography>Cantidad</Typography>
                <TextField
                  name="total"
                  value={total}
                  onChange={handleTextChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Button
          disabled={loadingXlsx}
          startIcon={
            loadingXlsx ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              <GetApp />
            )
          }
          variant="outlined"
          style={{
            margin: '10px 0px 30px 0px',
          }}
          onClick={handleSalesDownload}
        >
          Descargar información de ventas
        </Button>
        <Grid item xs={12} className={classes.subSection}>
          <Typography>Códigos multiples</Typography>
          <TextField
            name="multipleCodes"
            value={multipleCodes}
            multiline
            placeholder="Ingresa un código en cada línea"
            onBlur={(e) => {
              e.target.value = stringToMultiline(e.target.value);
              handleTextChange(e);
            }}
            onChange={(e) => {
              e.target.value = stringToMultiline(e.target.value);
              handleTextChange(e);
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

PromotionalCode.propTypes = {
  promotionalCode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    rule: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    isStackable: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  liftPromotionalCode: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
};

PromotionalCode.defaultProps = {
  handleRemove: () => { },
};

export default PromotionalCode;
