import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute';
import './App.css';
import { Login, AppContainer } from './views';

function App() {
  return (
    <Router>
      <Route exact path="/" component={Login} />
      <PrivateRoute path="/app" component={AppContainer} />
    </Router>
  );
}

export default App;
