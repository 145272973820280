import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { formatMoney } from '../../../helpers/money';
import { getRandomColor } from '../../../helpers/algorithms';

const SalesPerPeriodPerElement = ({ labels, dataset }) => {
  const canvasRef = createRef();
  const [chartObject, setChartObject] = useState(null);

  useEffect(() => {
    const datasets = Object.keys(dataset).filter((key) => key !== 'toJSON').map((key) => ({
      label: key,
      data: Object.values(dataset[key]),
      backgroundColor: getRandomColor(),
    }));
    if (!chartObject) {
      // eslint-disable-next-line no-new
      const newChartObject = new Chart(canvasRef.current, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          tooltips: {
            callbacks: {
              label(tooltipItem) {
                return `${datasets[tooltipItem.datasetIndex].label} $${formatMoney(tooltipItem.value)}`;
              },
            },
          },
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
        },
      });
      setChartObject(newChartObject);
    } else {
      chartObject.data.labels = labels;
      chartObject.data.datasets = datasets;
      chartObject.update();
    }
  }, [canvasRef, dataset, labels]);

  return (
    <canvas ref={canvasRef} />
  );
};

SalesPerPeriodPerElement.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dataset: PropTypes.object.isRequired,
};

export default SalesPerPeriodPerElement;
