/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState, useContext, createRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';
import moment from 'moment';
import {
  Typography,
  Grid,
  LinearProgress,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  GET_DELIVERY_ZONES,
  DELETE_DELIVERY_ZONE,
  UPDATE_DELIVERY_ZONE,
  CREATE_DELIVERY_ZONE,
  CREATE_DELIVERY_TIME,
  UPDATE_DELIVERY_TIME,
} from '../gql/deliveryZones';
import ErrorPage from './ErrorPage';
import { MainContext } from '../context/MainContext';
import { useMainStyles } from '../styles';
import { DeliveryZone } from '../components/deliveryZones';
import { OneButtonAlert, TwoButtonAlert } from '../components/dialogs';
import { deepCopy } from '../helpers/algorithms';
import { deliveryZoneHasEmptyField } from '../helpers/validators';

const DeliveryZones = () => {
  const classes = useMainStyles();
  const { store } = useContext(MainContext);
  const { data, loading, error, refetch } = useQuery(GET_DELIVERY_ZONES, {
    variables: { restaurantId: store.id },
  });
  const [createDeliveryZone] = useMutation(CREATE_DELIVERY_ZONE);
  const [updateDeliveryZone] = useMutation(UPDATE_DELIVERY_ZONE);
  const [deleteDeliveryZone] = useMutation(DELETE_DELIVERY_ZONE);
  const [createDeliveryTime] = useMutation(CREATE_DELIVERY_TIME);
  const [updateDeliveryTime] = useMutation(UPDATE_DELIVERY_TIME);
  const [deliveryZones, setDeliveryZones] = useState(null);
  const [selectedDeliveryZone, setSelectedDeliveryZone] = useState(null);
  const [formError, setFormError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);

  let circlesRef = [];

  useEffect(() => {
    if (data) {
      const { zones: dbDeliveryZones } = data;
      const sortedDeliveryZones = deepCopy(dbDeliveryZones)
        .sort((a, b) => a.created_at <= b.created_at ? 1 : -1);
      setDeliveryZones(sortedDeliveryZones);
      if (dbDeliveryZones.length > 0) {
        const newSelectedDeliveryZone = sortedDeliveryZones[0];
        setSelectedDeliveryZone(newSelectedDeliveryZone);
        setMapCenter({ lat: newSelectedDeliveryZone.lat, lng: newSelectedDeliveryZone.lng });
      } else {
        // lat long from Plaza de armas
        setMapCenter({ lat: -33.4785695, lng: -70.7333543 });
      }
    }
  }, [data]);

  const handleRefetch = async () => {
    setRefetchLoading(true);
    await refetch();
    setRefetchLoading(false);
  };

  const getPromises = async (_deliveryZones) => {
    const promises = [];
    await _deliveryZones.forEach(async (pc) => {
      const {
        id,
        name,
        delivery_cost: deliveryCost,
        radius,
        lat,
        lng,
        minimum_order: minimumOrder,
        isActive,
        isNew,
        modified,
        deliveryTimesPromises,
      } = pc;

      const variables = {
        name,
        radius,
        lat,
        lng,
        isActive,
        id,
        delivery_cost: parseFloat(deliveryCost),
        minimum_order: parseFloat(minimumOrder),
      };
      if (isNew) {
        const deliveryTimesPromisesResponse = await Promise.all(deliveryTimesPromises);
        const deliveryTimesIds = deliveryTimesPromisesResponse
          .map((response) => response.data.createDeliveryTime.deliveryTime.id);
        promises.push(createDeliveryZone({
          variables: {
            ...variables,
            delivery_times: deliveryTimesIds,
            restaurant: store.id,
          },
        }));
      } else if (modified) {
        promises.push(updateDeliveryZone({ variables }));
      }
    });
    return promises;
  };

  const handleSaveChanges = async () => {
    const modifiedDeliveryZones = deliveryZones.filter((dz) => dz.modified && !dz.isNew);
    const createdDeliveryZones = deliveryZones.filter((dz) => dz.isNew);
    if (modifiedDeliveryZones.length <= 0 && createdDeliveryZones.length <= 0) {
      setFormError('No realizaste cambios');
      return;
    }
    setSaveLoading(true);
    let scopeFormError = '';
    [...modifiedDeliveryZones, ...createdDeliveryZones].forEach((dz) => {
      if (deliveryZoneHasEmptyField(dz)) {
        scopeFormError = `La zona ${dz.id} tiene un campo vacío`;
      }
    });
    if (scopeFormError) {
      setFormError(scopeFormError);
      setSaveLoading(false);
      return;
    }

    // Create and modify delivery times
    const newDeliveryZones = deliveryZones.map((dz) => {
      const deliveryTimesPromises = [];
      dz.delivery_times.forEach((dt) => {
        const { hours_and_time: ht, day, id } = dt;
        const newHt = ht.map(({ start, end, time, id: _id, isNew }) => {
          if (isNew) return { start, end, time: parseInt(time, 10) };
          return { start, end, time: parseInt(time, 10), id: _id };
        });
        if (dt.isNew) {
          deliveryTimesPromises.push(createDeliveryTime({
            variables: {
              day,
              hours_and_time: newHt,
            },
          }));
        }
        if (dt.modified && !dt.isNew) {
          updateDeliveryTime({
            variables: {
              id,
              day,
              hours_and_time: newHt,
            },
          });
        }
      });
      return ({
        ...dz,
        deliveryTimesPromises,
      });
    });
    const promises = await getPromises(newDeliveryZones);
    await Promise.all(promises);
    setFormError('');
    setSaveLoading(false);
    setAlertOpen(true);
  };

  const handleNewDeliveryZone = (e) => {
    const { lat, lng } = e.latLng;
    const newDeliveryZones = [...deliveryZones];
    const currentNewDeliveryZones = newDeliveryZones.filter((dz) => dz.isNew);
    const tempId = `nueva ${currentNewDeliveryZones.length + 1}`;
    const newDeliveryZone = {
      id: tempId,
      name: '',
      lat: lat(),
      lng: lng(),
      radius: 1000,
      delivery_cost: 0,
      minimum_order: 0,
      // Create empty delivery times for each day
      delivery_times: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        .map((day) => ({ day, isNew: true, hours_and_time: [], id: Math.random() })),
      isActive: true,
      isNew: true,
      created_at: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    };
    newDeliveryZones.push(newDeliveryZone);
    setSelectedDeliveryZone(newDeliveryZone);
    setDeliveryZones(newDeliveryZones);
  };

  const deleteDeliveryZoneFromState = () => {
    const newDeliveryZones = [...deliveryZones].filter((dz) => dz.id !== selectedDeliveryZone.id);
    setDeliveryZones(newDeliveryZones);
    setSelectedDeliveryZone(null);
  };

  const deleteOldDeliveryZone = () => {
    setDeleteLoading(true);
    deleteDeliveryZone({ variables: { id: selectedDeliveryZone.id } })
      .then(() => {
        deleteDeliveryZoneFromState();
        setConfirmDialog(false);
        setDeleteLoading(false);
      })
      .catch((_error) => {
        console.error(_error);
        setConfirmDialog(false);
        setDeleteLoading(false);
      });
  };

  const handleRemoveDeliveryZone = () => {
    if (!selectedDeliveryZone.isNew) {
      setConfirmDialog(true);
      return;
    }
    deleteDeliveryZoneFromState();
  };

  const handleDeliveryZoneChange = (deliveryZone) => {
    const newDeliveryZones = [...deliveryZones];
    const index = newDeliveryZones.findIndex((dz) => deliveryZone.id === dz.id);
    newDeliveryZones[index] = { ...deliveryZone, modified: true };
    setDeliveryZones(newDeliveryZones);
  };

  const handleDeliveryZoneMapClick = (e, deliveryZone) => {
    const { lat, lng } = e.latLng;
    setMapCenter({ lat: lat(), lng: lng() });
    setSelectedDeliveryZone(deliveryZone);
  };

  const handleCircleCenterChange = (i) => {
    if (circlesRef[i].current) {
      const { lat, lng } = circlesRef[i].current.state.circle.center;
      const newDeliveryZones = [...deliveryZones];
      newDeliveryZones[i] = {
        ...newDeliveryZones[i],
        lat: parseFloat(lat()),
        lng: parseFloat(lng()),
        modified: true,
      };
      setDeliveryZones(newDeliveryZones);
    }
  };

  const handleCircleRadiusChange = (i) => {
    if (circlesRef[i].current) {
      const newDeliveryZones = [...deliveryZones];
      newDeliveryZones[i] = {
        ...newDeliveryZones[i],
        radius: parseInt(circlesRef[i].current.state.circle.radius, 10),
        modified: true,
      };
      setDeliveryZones(newDeliveryZones);
    }
  };

  if (error) return <ErrorPage />;
  if (loading || !deliveryZones || refetchLoading) return <LinearProgress />;

  circlesRef = deliveryZones.map(() => createRef());

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" className={classes.header}>
          Zonas de despacho
        </Typography>
      </Grid>
      {deliveryZones.length === 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Crea tu primera zona de despacho haciendo click en el mapa
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <LoadScript
          loadingElement={<CircularProgress />}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <GoogleMap
            onClick={handleNewDeliveryZone}
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={12}
          >
            <>
              {deliveryZones.map((deliveryZone, i) => {
                const { id, lat, lng, radius } = deliveryZone;
                const circleOptions = {
                  strokeOpacity: 0.8,
                  strokeWeight: 1,
                  fillOpacity: 0.35,
                };
                return (
                  <div key={id}>
                    <Circle
                      editable
                      draggable
                      ref={circlesRef[i]}
                      onRadiusChanged={() => handleCircleRadiusChange(i)}
                      onCenterChanged={() => handleCircleCenterChange(i)}
                      center={{ lat, lng }}
                      radius={parseInt(radius, 10)}
                      onClick={(e) => handleDeliveryZoneMapClick(e, deliveryZone)}
                      options={(selectedDeliveryZone && id === selectedDeliveryZone.id)
                        ? { ...circleOptions, strokeColor: '#81b214', fillColor: '#81b214' }
                        : { ...circleOptions, strokeColor: '#07689f', fillColor: '#07689f' }}
                    />
                  </div>
                );
              })}
            </>
          </GoogleMap>
        </LoadScript>
      </Grid>
      <Grid item xs={12}>
        {selectedDeliveryZone && (
          <div style={{ padding: '2rem 0 1rem' }}>
            <DeliveryZone
              deliveryZone={selectedDeliveryZone}
              liftDeliveryZone={
                (_deliveryZone) => handleDeliveryZoneChange(_deliveryZone)
              }
              handleRemove={handleRemoveDeliveryZone}
            />
          </div>
        )}
      </Grid>
      <Grid container direction="column" alignItems="flex-end" className={classes.saveButtonContainer}>
        <Grid item xs={12} md={4} lg={3} className={classes.saveButtonItem}>
          {Boolean(formError) && (
            <Typography
              component="div"
              className={classes.subSection}
              align="center"
              color="error"
              variant="caption"
              style={{ margin: 0 }}
            >
              {formError}
            </Typography>
          )}
          <Button
            onClick={handleSaveChanges}
            color="primary"
            disabled={saveLoading}
            variant="contained"
            fullWidth
          >
            {saveLoading ? <CircularProgress size={16} /> : 'Guardar Cambios'}
          </Button>
        </Grid>
      </Grid>
      <OneButtonAlert
        open={alertOpen}
        onClose={() => { setAlertOpen(false); handleRefetch(); }}
        title="Cambios realizados con éxito"
      />
      <TwoButtonAlert
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        title="¡Esta acción es irreversible!"
        message="¿Seguro que quieres eliminar esta zona?"
        onAccept={deleteOldDeliveryZone}
        loading={deleteLoading}
      />
    </Grid>
  );
};

export default DeliveryZones;
