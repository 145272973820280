import React, { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  LinearProgress,
  Typography,
  TextField,
  Switch,
  Button,
  IconButton,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { MainContext } from '../context/MainContext';
import {
  GET_STORE,
  UPDATE_STORE,
  UPDATE_OPEN_HOUR,
  DELETE_OPEN_HOUR,
  CREATE_OPEN_HOUR,
  UPDATE_BANNER,
  CREATE_IN_STORE_MENU,
  UPDATE_IN_STORE_MENU,
} from '../gql/store';
import { MULTIPLE_UPLOAD, UNIQUE_UPLOAD } from '../gql/utils';
import ErrorPage from './ErrorPage';
import { OneButtonAlert } from '../components/dialogs';
import { translateDay } from '../helpers/i18';
import { capitalize } from '../helpers/strings';
import { orderOfDay } from '../helpers/date';
import { TimeSelect, DaySelect } from '../components/inputs';
import { MultipleImageUpload, UniqueFileUpload } from '../components/utils';
import { useMainStyles } from '../styles';
import { InStoreMenus } from '../components/storeInformation';

const StoreInformation = () => {
  const classes = useMainStyles();
  const { store: contextStore, setStore: setContextStore } = useContext(MainContext);
  const { loading, error, data } = useQuery(GET_STORE, { variables: { id: contextStore.id } });
  const [updateOpenHour] = useMutation(UPDATE_OPEN_HOUR);
  const [deleteOpenHour] = useMutation(DELETE_OPEN_HOUR);
  const [createOpenHour] = useMutation(CREATE_OPEN_HOUR);
  const [createInStoreMenu] = useMutation(CREATE_IN_STORE_MENU);
  const [updateInStoreMenu] = useMutation(UPDATE_IN_STORE_MENU);
  const [updateBanner] = useMutation(UPDATE_BANNER);
  const [multipleUpload] = useMutation(MULTIPLE_UPLOAD);
  const [uniqueUpload] = useMutation(UNIQUE_UPLOAD);
  const [updateStore] = useMutation(UPDATE_STORE);
  const [saveLoading, setSaveLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [store, setStore] = useState(null);
  const [desktopBanners, setDesktopBanners] = useState([]);
  const [mobileBanners, setMobileBanners] = useState([]);
  const [logo, setLogo] = useState(null);
  const [inStoreMenus, setInStoreMenus] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    if (data) {
      const { restaurant: dbStore } = data;
      const newStore = {
        ...contextStore,
        ...dbStore,
        open_hours: dbStore.open_hours.map((openHour) => ({
          ...openHour,
          order: orderOfDay[openHour.day],
        })),
      };
      setStore(newStore);
      setContextStore(newStore);
      setDesktopBanners(dbStore.banner.desktop);
      setMobileBanners(dbStore.banner.mobile);
      setLogo(dbStore.logo);
      setInStoreMenus(dbStore.in_store_menus);
    }
  }, [data]);

  const handleOpenChange = (event) => {
    setStore((oldStore) => ({
      ...oldStore, isOpen: event.target.checked,
    }));
  };

  const handleSchedulingChange = (event) => {
    setStore((oldStore) => ({
      ...oldStore, allowsScheduling: event.target.checked,
    }));
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setStore((oldStore) => ({
      ...oldStore, [name]: value,
    }));
  };

  const handleNewOpenHour = () => {
    const newOpenHours = [...store.open_hours];
    // Concatenate all ids to make sure tempId is not equal to any other id
    const tempId = parseInt(Math.random() * 100000000, 10);
    const newOpenHour = {
      id: tempId,
      day: '',
      new: true,
      order: 0,
      hours: [{
        start: '09:00:00.000',
        end: '21:00:00.000',
      }],
    };
    newOpenHours.push(newOpenHour);
    setStore((oldStore) => ({
      ...oldStore, open_hours: newOpenHours,
    }));
  };

  const handleOpenHourDelete = (id) => {
    const newOpenHours = [...store.open_hours].filter((openHour) => openHour.id !== id);
    setStore((oldStore) => ({
      ...oldStore, open_hours: newOpenHours,
    }));
    const openHour = store.open_hours.find((_openHour) => _openHour.id === id);
    // Only delete if the hour has not been created now, or it will not exists in the db
    if (!openHour.new) deleteOpenHour({ variables: { id } });
  };

  const handleDayChange = (id, day) => {
    const newOpenHours = [...store.open_hours];
    const newOpenHour = newOpenHours.find((openHour) => openHour.id === id);
    newOpenHour.day = day;
    newOpenHour.order = orderOfDay[day];
    setStore((oldStore) => ({
      ...oldStore, open_hours: newOpenHours,
    }));
  };

  const handleHourChange = (id, i, hour, type) => {
    // Workaround necessary because apollo cache is read only
    let newStore = { ...store };
    const newOpenHours = [...newStore.open_hours];
    const newOpenHourIndex = newOpenHours.findIndex((openHour) => openHour.id === id);
    let newOpenHour = { ...newOpenHours.find((openHour) => openHour.id === id) };
    const newHours = [...newOpenHour.hours];
    // Add back ss.SSS to comply with backend format (HH:mm:ss.SSS)
    const newHour = { ...newHours[i], [type]: `${hour}:00.000` };
    newHours[i] = newHour;
    newOpenHour = { ...newOpenHour, hours: newHours, modified: true };
    newOpenHours[newOpenHourIndex] = newOpenHour;
    newStore = { ...newStore, open_hours: newOpenHours };
    setStore((oldStore) => ({
      ...oldStore, open_hours: newOpenHours,
    }));
  };

  const uploadFile = async (file) => {
    // If the file is null, it means it has been deleted
    if (!file) return null;
    // If the file has ID, it means it did not change
    if (file.id) return file.id;
    setLoadingMessage('Subiendo archivos');
    const uploadedLogoResponse = await uniqueUpload({
      variables: {
        file: file,
      },
    });
    // eslint-disable-next-line consistent-return
    return uploadedLogoResponse.data.upload.id;
  };

  const saveBanners = async () => {
    // Not having an id means that the image is new
    const desktopUploads = desktopBanners.filter((banner) => !banner.id);
    const mobileUploads = mobileBanners.filter((banner) => !banner.id);
    let desktopNewUploads = [];
    let mobileNewUploads = [];
    if (desktopUploads.length > 0) {
      setLoadingMessage('Subiendo banners de escritorio');
      const desktopResponse = await multipleUpload({
        variables: {
          files: desktopUploads,
        },
      });
      desktopNewUploads = desktopResponse.data.multipleUpload;
    }
    if (mobileUploads.length > 0) {
      setLoadingMessage('Subiendo banners mobile');
      const mobileResponse = await multipleUpload({
        variables: {
          files: mobileUploads,
        },
      });
      mobileNewUploads = mobileResponse.data.multipleUpload;
    }
    const newDesktopBanners = [
      ...desktopBanners.filter((banner) => banner.id),
      ...desktopNewUploads,
    ];
    const newMobileBanners = [
      ...mobileBanners.filter((banner) => banner.id),
      ...mobileNewUploads,
    ];
    await updateBanner({
      variables: {
        bannerId: store.banner.id,
        desktop: newDesktopBanners.map((image) => image.id),
        mobile: newMobileBanners.map((image) => image.id),
      },
    });
  };

  const handleSaveChanges = async () => {
    for (let i = 0; i < store.open_hours.length; i += 1) {
      const openHours = store.open_hours[i];
      if (openHours.day === '') {
        setFormError('Tienes que escoger un día para el horario');
        return;
      }
    }
    setLoadingMessage('Guardando datos de la tienda');
    setFormError('');
    setSaveLoading(true);
    store.open_hours.forEach((openHour) => {
      const { id, day, hours } = openHour;
      if (openHour.modified) {
        updateOpenHour({
          variables: {
            id,
            day,
            hours: hours.map(({ start, end, id: _id }) => ({ start, end, _id })),
          },
        });
      }
    });
    let openHoursIds = store.open_hours
      .filter((openHour) => !openHour.new)
      .map((openHour) => openHour.id);
    const newOpenHours = store.open_hours.filter((openHour) => openHour.new);
    const createOpenHoursPromises = [];
    for (let i = 0; i < newOpenHours.length; i += 1) {
      const { day, hours } = newOpenHours[i];
      createOpenHoursPromises.push(
        createOpenHour({
          variables: {
            day,
            hours: hours.map(({ start, end, id }) => ({ start, end, id })),
          },
        }),
      );
    }
    const responses = await Promise.all(createOpenHoursPromises);
    const newOpenHoursIds = responses.map((response) => response.data.createOpenHour.openHour.id);
    openHoursIds = [...openHoursIds, ...newOpenHoursIds];
    const newLogo = await uploadFile(logo);

    const inStoreMenusIds = inStoreMenus.filter((inStoreMenu) => !inStoreMenu.new).map((inStoreMenu) => inStoreMenu.id);
    for (let i = 0; i < inStoreMenus.length; i++) {
      const inStoreMenu = inStoreMenus[i];
      const { id, name, file, new: isNew, updatedName, updatedFile } = inStoreMenu;
      if (isNew) {
        const menuFile = await uploadFile(file);
        const createdMenu = await createInStoreMenu({
          variables: {
            name,
            file: menuFile,
          }
        })
        inStoreMenusIds.push(createdMenu.data.createInStoreMenu.inStoreMenu.id);
      }
      if (updatedFile || updatedName) {
        let menuFile = file;
        if (updatedFile) menuFile = await uploadFile(file);
        await updateInStoreMenu({
          variables: {
            id,
            name,
            file: menuFile,
          }
        })
      }
    }
    // eslint-disable-next-line camelcase
    const { id, name, short_address, isOpen, pickup_time, allowsScheduling } = store;
    await updateStore({
      variables: {
        id,
        name,
        short_address,
        isOpen,
        open_hours: openHoursIds,
        allowsScheduling,
        pickup_time: parseInt(pickup_time, 10),
        logo: newLogo,
        inStoreMenus: inStoreMenusIds,
      },
    });
    await saveBanners();
    setAlertOpen(true);
    setSaveLoading(false);
    window.location.reload();
  };

  if (error) return <ErrorPage />;
  if (loading || !store) return <LinearProgress />;

  const {
    isOpen,
    allowsScheduling,
    name,
    short_address: shortAddress,
    pickup_time: pickupTime,
    open_hours: openHours,
  } = store;

  const daysWithOpenHours = openHours.map((openHour) => openHour.day);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" className={classes.header}>Datos del negocio</Typography>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.leftSection}>
        <div className={classes.section}>
          <Typography gutterBottom><b>Estado del sitio</b></Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
            <Typography>{isOpen ? 'Abierto' : 'Cerrado'}</Typography>
            <Switch
              checked={isOpen}
              onChange={handleOpenChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <Typography gutterBottom><b>Permitir órdenes programadas</b></Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>{allowsScheduling ? 'Sí' : 'No'}</Typography>
            <Switch
              checked={allowsScheduling}
              onChange={handleSchedulingChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
        <div className={classes.section}>
          <Typography className={classes.subSection}><b>Datos</b></Typography>
          <div className={classes.subSection}>
            <Typography>Nombre</Typography>
            <TextField
              name="name"
              value={name}
              onChange={handleTextChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className={classes.subSection}>
            <Typography>Direccion</Typography>
            <TextField
              multiline
              minRows={2}
              name="short_address"
              value={shortAddress}
              onChange={handleTextChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className={classes.subSection}>
            <Typography>Tiempo estimado de retiro en local</Typography>
            <TextField
              name="pickup_time"
              value={pickupTime}
              onChange={handleTextChange}
              variant="outlined"
              type="number"
              fullWidth
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.rightSection}>
        <div className={classes.section}>
          <Typography className={classes.subSection}>
            <b>Horarios de apertura (uno por día)</b>
          </Typography>
          {openHours.length === 0 && (
            <Typography>
              Crea tus horarios de apertura para cada día con el siguiente botón
            </Typography>
          )}
          <Grid container>
            {openHours
              .filter((openHour) => openHour.day !== '')
              .sort((a, b) => a.order - b.order)
              .map((openHour) => (
                <Grid item xs={12} xl={6} key={openHour.id} className={classes.subSection}>
                  <Typography>{capitalize(translateDay(openHour.day, 'ES'))}</Typography>
                  {openHour.hours.map((hour, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                      {/* First 5 characters are in the form HH:MM,
                because the variable comes as HH:MM:SS.sss */}
                      <TimeSelect
                        currentSelection={hour.start.substring(0, 5)}
                        onChange={(newHour) => handleHourChange(openHour.id, i, newHour, 'start')}
                        maxHour={hour.end.substring(0, 5)}
                      />
                      <Typography style={{ margin: '0 1rem' }}>-</Typography>
                      <TimeSelect
                        currentSelection={hour.end.substring(0, 5)}
                        onChange={(newHour) => handleHourChange(openHour.id, i, newHour, 'end')}
                        minHour={hour.start.substring(0, 5)}
                      />
                      <IconButton
                        className={classes.deleteButton}
                        onClick={() => handleOpenHourDelete(openHour.id)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </div>
                  ))}
                </Grid>
              ))}
          </Grid>
          <Grid container>
            {openHours.filter((openHour) => openHour.day === '').map((openHour) => (
              <Grid item xs={12} md={6} key={openHour.id} className={classes.subSection}>
                <DaySelect
                  doNotInclude={daysWithOpenHours}
                  onChange={(day) => handleDayChange(openHour.id, day)}
                />
                {openHour.hours.map((hour, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                    {/* First 5 characters are in the form HH:MM,
                because the variable comes as HH:MM:SS.sss */}
                    <TimeSelect
                      currentSelection={hour.start.substring(0, 5)}
                      onChange={(newHour) => handleHourChange(openHour.id, i, newHour, 'start')}
                      maxHour={hour.end.substring(0, 5)}
                    />
                    <Typography style={{ margin: '0 1rem' }}>-</Typography>
                    <TimeSelect
                      currentSelection={hour.end.substring(0, 5)}
                      onChange={(newHour) => handleHourChange(openHour.id, i, newHour, 'end')}
                      minHour={hour.start.substring(0, 5)}
                    />
                    <IconButton
                      className={classes.deleteButton}
                      onClick={() => handleOpenHourDelete(openHour.id)}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
          {/* If open hours is less than 7, it means the store
        has not assigned an open hour for each day */}
          {openHours.length < 7 && (
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={handleNewOpenHour}
            >
              <Add color="primary" style={{ fontSize: 16 }} />
              <Typography color="primary">Añadir nuevo día</Typography>
            </Button>
          )}
        </div>
      </Grid>
      <Grid container direction="column" className={classes.section}>
        <Typography className={classes.subSection}>
          <b>Banner promocional</b>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MultipleImageUpload
              label="Versión escritorio dimensiones 4:1"
              onChange={setDesktopBanners}
              initialValue={desktopBanners}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleImageUpload
              label="Versión mobile dimensiones 3:1"
              onChange={setMobileBanners}
              initialValue={mobileBanners}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography gutterBottom><b>Logo</b></Typography>
          <UniqueFileUpload
            accept="image/*"
            label="Elige o cambia tu logo"
            onChange={setLogo}
            initialValue={logo}
            placeholder="Clickea para seleccionar el logo"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item xs={6}>
          <InStoreMenus inStoreMenus={inStoreMenus} handleInStoreMenusChange={setInStoreMenus}/>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-end">
        <Grid item xs={12} md={3} style={{ width: '100%' }}>
          {Boolean(formError) && (
            <Typography
              component="div"
              className={classes.subSection}
              align="center"
              color="error"
              variant="caption"
              style={{ margin: 0 }}
            >
              {formError}
            </Typography>
          )}
          <Button
            onClick={handleSaveChanges}
            color="primary"
            disabled={saveLoading}
            variant="contained"
            fullWidth
            endIcon={saveLoading ? <CircularProgress size={16} /> : null}
          >
            {saveLoading ? loadingMessage : 'Guardar Cambios'}
          </Button>
        </Grid>
      </Grid>
      <OneButtonAlert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title="Cambios realizados con éxito"
      />
    </Grid>
  );
};

export default StoreInformation;
