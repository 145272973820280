import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check, Refresh } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { useMainStyles } from '../styles';
import { MainContext } from '../context/MainContext';
import { CONNECT_TOTEAT } from '../gql/store';
import { OneButtonAlert } from '../components/dialogs';

const useStyles = makeStyles((theme) => ({
  check: {
    color: theme.palette.success.main,
  },
  cross: {
    color: theme.palette.error.main,
  },
  categoryPaper: {
    padding: '1rem',
  },
}));

const EMPTY_ERP_DATA = {
  xir: '',
  xil: '',
  xiu: '',
  xapitoken: '',
};

const ERPIntegration = () => {
  const classes = { ...useMainStyles(), ...useStyles() };
  const { store, setStore } = useContext(MainContext);
  const [connectToteat, {
    data: connectToteatData,
    loading: connectToteatLoading,
    error: connectToteatError,
  }] = useLazyQuery(CONNECT_TOTEAT, { fetchPolicy: 'network-only' });
  const [updateCategories, {
    data: updateCategoriesData,
    loading: updateCategoriesLoading,
    error: updateCategoriesError,
  }] = useLazyQuery(CONNECT_TOTEAT, { fetchPolicy: 'network-only' });
  const [ERPData, setERPData] = useState(store.erpConfig ? store.erpConfig : EMPTY_ERP_DATA);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [categories, setCategories] = useState(store.products ? [...store.products] : []);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(null);

  // Handle when store is changed in header
  useEffect(() => {
    if (store.id !== currentStoreId) {
      const newERPData = store.erpConfig;
      connectToteat({
        variables: { ...newERPData, restaurantId: store.id },
        fetchPolicy: 'network-only',
      });
      setERPData(newERPData);
      setCurrentStoreId(store.id);
    }
  }, [store]);

  useEffect(() => {
    connectToteat({
      variables: { ...ERPData, restaurantId: store.id },
      fetchPolicy: 'network-only',
    });
  }, []);

  useEffect(() => {
    if (connectToteatData) {
      setConnectionStatus(true);
      const { connectToteat: { products, erpConfig } } = connectToteatData;
      setCategories(products);
      setERPData(erpConfig);
      const newStore = { ...store, products, erpConfig };
      setStore(newStore);
    }
  }, [connectToteatData]);

  useEffect(() => {
    if (connectToteatError) setConnectionStatus(false);
  }, [connectToteatError]);

  useEffect(() => {
    if (updateCategoriesData) {
      const { connectToteat: { erpConfig } } = updateCategoriesData;
      setERPData(erpConfig);
      const newStore = { ...store, erpConfig };
      setStore(newStore);
      setSuccessAlertOpen(true);
    }
  }, [updateCategoriesData]);

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setERPData((oldStore) => ({
      ...oldStore, [name]: value,
    }));
  };

  const getConnectionStatus = () => {
    if (connectToteatLoading) {
      return <CircularProgress size={16} style={{ marginLeft: '1rem' }} />;
    }
    return (
      <Grid container>
        <Check className={classes.check} />
        <Typography>
          Conectado
        </Typography>
      </Grid>
    );
  };

  const getEmptyCategoriesMessage = () => {
    if (connectToteatLoading) return '';
    if (connectionStatus) return 'Presiona el botón actualizar para administrar las categorías que se mostrarán en la página';
    return 'Para administrar las categorías que se mostrarán en la página, la conexión a Toteat debe estar activa';
  };

  const handleUpdateCategories = () => connectToteat({
    variables: { ...ERPData, restaurantId: store.id },
  });

  const handleCategoryShow = (e, id) => {
    const { target: { checked } } = e;
    const categoryIndex = categories.findIndex((category) => category.id === id);
    const newCategories = [...categories];
    newCategories[categoryIndex] = { ...newCategories[categoryIndex], show: checked };
    setCategories(newCategories);
  };

  const handleSaveChanges = () => {
    const categoriesHidden = categories
      .filter((category) => !category.show)
      .map((category) => category.id);
    const newErpConfig = { ...store.erpConfig, categoriesHidden };
    updateCategories({
      variables: { ...newErpConfig, restaurantId: store.id },
    });
  };

  const handleConnect = () => connectToteat({
    variables: { ...ERPData, restaurantId: store.id },
  });

  const {
    xir,
    xil,
    xiu,
    xapitoken,
  } = ERPData;

  return (
    <Grid container className={classes.container}>
      {!connectToteatLoading && !connectionStatus
        ? (
          <>
            <Grid item xs={12} className={classes.header}>
              <Typography variant="h4" align="center">Conéctate a Toteat</Typography>
              <Typography>
                Ingresa las credenciales de tu POS Toteat para sincronizar tus platos.
                Si necesitas ayuda con la activación, contáctanos por Whatsapp al +56 9 8901 0221
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.section} direction="column">
                <Grid item xs={12}>
                  <Typography className={classes.subSection}><b>Datos</b></Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.subSection}>
                  <Typography>Identificador del restaurante (xir)</Typography>
                  <TextField
                    type="number"
                    placeholder="Ejemplo: 7384781048320184"
                    name="xir"
                    value={xir}
                    onChange={handleTextChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.subSection}>
                  <Typography>Identificador del local (xil)</Typography>
                  <TextField
                    type="number"
                    placeholder="Ejemplo: 1"
                    name="xil"
                    value={xil}
                    onChange={handleTextChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.subSection}>
                  <Typography>Identificador del usuario (xiu)</Typography>
                  <TextField
                    type="number"
                    placeholder="Ejemplo: 1001"
                    name="xiu"
                    value={xiu}
                    onChange={handleTextChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.subSection}>
                  <Typography>Token de integración (xapitoken)</Typography>
                  <TextField
                    name="xapitoken"
                    placeholder="Ejemplo: H83j091JD093ks0DK0938MjS131lmnJJ"
                    value={xapitoken}
                    onChange={handleTextChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.subSection}>
                  {!connectToteatLoading && !connectionStatus && (
                    <Typography variant="body2" color="error">Conexión inválida</Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={connectToteatLoading}
                    endIcon={connectToteatLoading ? <CircularProgress size={16} /> : null}
                    onClick={handleConnect}
                  >
                    Conectar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
        : (
          <Grid item xs={12} className={classes.header}>
            <Typography variant="h4" align="center">Conexión a Toteat</Typography>
            <Grid container direction="column">
              <Typography className={classes.subSection}><b>Estado de la conexión</b></Typography>
              {getConnectionStatus()}
            </Grid>
          </Grid>
        )}
      <Grid item xs={12}>
        <Grid container className={classes.section} direction="column">
          <Grid item xs={12}>
            <Grid container alignItems="center" className={classes.subSection}>
              <Typography style={{ marginRight: '1rem' }}><b>Categorías</b></Typography>
              <Button
                color="primary"
                startIcon={<Refresh />}
                onClick={handleUpdateCategories}
                disabled={connectToteatLoading}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
          {connectToteatLoading && <LinearProgress />}
          {!connectToteatLoading && categories.length > 0
            ? (
              <Grid container spacing={2}>
                {categories.map((category) => (
                  <Grid item xs={12} md={6} key={category.id}>
                    <Paper className={classes.categoryPaper}>
                      <Grid container>
                        <Grid item xs={7}>
                          <Grid container direction="column">
                            <Typography>
                              {category.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {category.id}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                          <Grid container alignItems="center">
                            <Switch
                              checked={category.show}
                              onChange={(e) => handleCategoryShow(e, category.id)}
                            />
                            {category.show
                              ? (
                                <Typography>Mostrar en la página</Typography>
                              )
                              : (
                                <Typography>No mostrar en la página</Typography>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )
            : (
              <Typography>{getEmptyCategoriesMessage()}</Typography>
            )}
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-end">
        <Grid item xs={12} md={3} style={{ width: '100%' }}>
          {updateCategoriesError && (
            <Typography
              component="div"
              className={classes.subSection}
              align="center"
              color="error"
              variant="caption"
              style={{ margin: 0 }}
            >
              Ocurrió un error al guardar las categorías
            </Typography>
          )}
          <Button
            onClick={handleSaveChanges}
            color="primary"
            disabled={updateCategoriesLoading}
            variant="contained"
            fullWidth
            endIcon={updateCategoriesLoading ? <CircularProgress size={16} /> : null}
          >
            Guardar Cambios
          </Button>
        </Grid>
      </Grid>
      <OneButtonAlert
        open={successAlertOpen}
        onClose={() => setSuccessAlertOpen(false)}
        title="Cambios realizados con éxito"
      />
    </Grid>
  );
};

export default ERPIntegration;
