import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { translatePromotionalCodeType } from '../../helpers/i18';

const discountTypes = [
  'checkDiscount',
  'categoryDiscount',
  'itemDiscount',
];

const YxZTypes = [
  'checkYxZ',
  'categoryYxZ',
  'itemYxZ',
];

const discountsRules = [
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
  '65',
  '70',
  '75',
  '80',
  '85',
  '90',
  '95',
  '100',
];

const YxZRules = [
  '2x1',
  '3x1',
  '4x1',
  '3x2',
  '4x2',
  '5x2',
  '4x3',
  '5x3',
  '6x3',
];

const PromotionalCodeRuleSelect = ({ selectedType, currentSelection, onChange }) => (
  <Select
    onChange={onChange}
    value={currentSelection}
    variant="outlined"
    fullWidth
  >
    {discountTypes.includes(selectedType) && (
      discountsRules.map((rule) => (
        <MenuItem key={rule} value={rule}>{rule}</MenuItem>
      ))
    )}
    {YxZTypes.includes(selectedType) && (
      YxZRules.map((rule) => (
        <MenuItem key={rule} value={rule}>{rule}</MenuItem>
      ))
    )}
  </Select>
);

PromotionalCodeRuleSelect.propTypes = {
  selectedType: PropTypes.oneOf([...discountTypes, ...YxZTypes]),
  currentSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PromotionalCodeRuleSelect.defaultProps = {
  selectedType: 'checkDiscount',
  currentSelection: '10',
};

export default PromotionalCodeRuleSelect;
