import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, LinearProgress, TextField, Typography } from '@material-ui/core';
import { OneButtonAlert } from '../components/dialogs';
import { GET_STORE, UPDATE_STORE } from '../gql/store';
import { MainContext } from '../context/MainContext';
import { useMutation, useQuery } from '@apollo/client';
import { useMainStyles } from '../styles';
import ErrorPage from './ErrorPage';


export default function ExternalToolsConfig() {
  const classes = useMainStyles()
  const { store: contextStore, setStore: setContextStore } = useContext(MainContext);
  const [googleTagManagerId, setGoogleTagManagerId] = useState('');
  const [facebookDomainVerification, setFacebookDomainVerification] = useState('');
  const { loading, error, data } = useQuery(GET_STORE, { variables: { id: contextStore.id } });
  const [store, setStore] = useState(contextStore);
  const [saveLoading, setSaveLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [updateStore] = useMutation(UPDATE_STORE);

  useEffect(() => {
    if (data) {
      const { restaurant: dbStore } = data;
      const newStore = {
        ...contextStore,
        ...dbStore,
      };
      setStore(newStore);
      setContextStore(newStore);
      setGoogleTagManagerId(newStore.googleTagManagerId);
      setFacebookDomainVerification(newStore.facebookDomainVerification);
    }
  }, [data]);

  const handleGoogleTagManagerIdChange = ({ target: { value } }) => {
    setGoogleTagManagerId(value);
  };

  const handleFacebookDomainVerificationChange = ({ target: { value } }) => {
    setFacebookDomainVerification(value);
  };

  const handleSaveChanges = () => {
    setSaveLoading(true);
    updateStore({
      variables: {
        id: store.id,
        googleTagManagerId,
        facebookDomainVerification,
      },
    })
      .then(() => {
        setAlertTitle('Cambios realizados con éxito');
        setAlertOpen(true);
      })
      .catch(() => {
        setAlertTitle('No se pudieron guardar los cambios');
        setAlertOpen(true);
      })
      .finally(() => {
        setSaveLoading(false);
      })
  }

  if (error) return <ErrorPage />;
  if (loading || !store) return <LinearProgress />;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} lg={6} className={classes.leftSection}>
        <div className={classes.section}>
          <Typography gutterBottom><b>Google Tag Manager ID (GTM-XXXXXXX)</b></Typography>
          <TextField
            value={googleTagManagerId}
            onChange={handleGoogleTagManagerIdChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <div className={classes.section}>
          <Typography gutterBottom><b>Verificación de dominio de Facebook (código después de 'content' y sin comillas)</b></Typography>
          <TextField
            value={facebookDomainVerification}
            onChange={handleFacebookDomainVerificationChange}
            variant="outlined"
            fullWidth
          />
        </div>
        <Button
          size="small"
          onClick={handleSaveChanges}
          color="primary"
          disabled={saveLoading}
          variant="contained"
          fullWidth
          endIcon={saveLoading ? <CircularProgress size={16} /> : null}
        >
          {saveLoading ? 'Guardando' : 'Guardar Cambios'}
        </Button>
      </Grid>
      <OneButtonAlert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title={alertTitle}
      />
    </Grid>
  )
}