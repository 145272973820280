import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  Storefront,
  Motorcycle,
  LocalOfferOutlined,
  MeetingRoomOutlined,
  TrendingUp,
  RecordVoiceOver,
  RestaurantMenu,
  Settings,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const textToRoute = {
  General: '/app/store-information',
  Productos: '/app/menu',
  Promociones: '/app/promotional-codes',
  Despachos: '/app/uber-deliveries',
  Ventas: '/app/dashboard/sales',
  Clientes: '/app/dashboard/users',
  Configuración: '/app/external-tools/config'
};

const textToIcon = {
  General: <Storefront />,
  Productos: <RestaurantMenu />,
  Promociones: <LocalOfferOutlined />,
  Despachos: <Motorcycle />,
  Ventas: <TrendingUp />,
  Clientes: <RecordVoiceOver />,
  Configuración: <Settings />,
};

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: '13%',
    [theme.breakpoints.up('lg')]: {
      top: 64,
    },
  },
  list: {
    paddingTop: 20,
  },
  listItem: {
    marginTop: '0.5rem',
  }
}));

const LeftNavigation = ({ open, anchor, onClose, variant, logout }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={anchor}
        open={open}
        onClose={onClose}
        variant={variant}
      >
        <List className={classes.list}>
          <ListItem>
            <Typography variant="subtitle1"><b>Gestiona tu negocio</b></Typography>
          </ListItem>
          {['General', 'Productos', 'Promociones', 'Despachos'].map((text) => (
            <Link to={textToRoute[text]} key={text} onClick={onClose}>
              <ListItem selected={location.pathname === textToRoute[text]} button>
                <ListItemIcon>
                  {textToIcon[text]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          ))}
          <ListItem className={classes.listItem}>
            <Typography variant="subtitle1"><b>Analiza tu negocio</b></Typography>
          </ListItem>
          {['Ventas', 'Clientes'].map((text) => (
            <Link to={textToRoute[text]} key={text} onClick={onClose}>
              <ListItem selected={location.pathname === textToRoute[text]} button>
                <ListItemIcon>
                  {textToIcon[text]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          ))}
          <ListItem className={classes.listItem}>
            <Typography variant="subtitle1"><b>Herramientas externas</b></Typography>
          </ListItem>
          {['Configuración'].map((text) => (
            <Link to={textToRoute[text]} key={text} onClick={onClose}>
              <ListItem selected={location.pathname === textToRoute[text]} button>
                <ListItemIcon>
                  {textToIcon[text]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          ))}
          {/* This means that the user is in md or down screen */}
          {variant === 'temporary' && (
            <>
              <Divider style={{ margin: '1rem' }} />
              <ListItem button onClick={logout}>
                <ListItemIcon>
                  <MeetingRoomOutlined />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>

    </>
  );
}

LeftNavigation.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};

LeftNavigation.defaultProps = {
  open: true,
  anchor: 'left',
};

export default LeftNavigation;
