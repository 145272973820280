/* eslint-disable no-confusing-arrow */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, Typography, FormControl, Input, Chip, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';
import { MainContext } from '../../context/MainContext';
import { GET_CATEGORIES, GET_ITEMS } from '../../gql/store';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  input: {
    padding: '0 20px 5px 0 !important',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'inherit',
  },
}));

const useData = (type) => {
  const { store } = useContext(MainContext);
  const [getItems, itemsOptions] = useLazyQuery(GET_ITEMS, {
    variables: { storeId: store.id },
  });
  const [getCategories, categoriesOptions] = useLazyQuery(GET_CATEGORIES, {
    variables: { storeId: store.id },
  });
  if (type === 'items') {
    return [getItems, { ...itemsOptions, type }];
  }
  if (type === 'categories') {
    return [getCategories, { ...categoriesOptions, type }];
  }
  return [null, {}];
};

// TODO: return object instead of only the names in onChange
const PromotionalCodeAffectedSelect = ({ currentSelection, onChange, type }) => {
  const classes = useStyles();
  const [affected, setAffected] = useState([]);
  const [getData, { data, error, loading }] = useData(type);

  useEffect(() => {
    if (getData) {
      getData();
      if (data) {
        const keys = Object.keys(data);
        setAffected(data[keys[0]]);
      }
    }
  }, [data, getData]);

  const handleChange = (e) => {
    onChange(affected.filter((value) => e.target.value.includes(value.name)));
  };

  if (loading) return <CircularProgress />;
  if (error || !getData) return null;

  return (
    <>
      <Typography gutterBottom>{type === 'items' ? 'Productos' : 'Categorías'}</Typography>
      <FormControl className={classes.formControl}>
        <Select
          multiple
          value={currentSelection.map((value) => value.name)}
          onChange={handleChange}
          input={<Input className={classes.input} />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
        >
          {[...affected].sort((a, b) => a.name >= b.name ? 1 : -1).map((value) => (
            <MenuItem key={value.name} value={value.name}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

PromotionalCodeAffectedSelect.propTypes = {
  currentSelection: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['items', 'categories', 'all']).isRequired,
};

PromotionalCodeAffectedSelect.defaultProps = {
  currentSelection: [],
};

export default PromotionalCodeAffectedSelect;
