import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

const TimePeriodSelect = ({ currentSelection, onChange }) => (
  <FormControl variant="outlined" style={{ minWidth: 120 }}>
    <Select
      value={currentSelection}
      onChange={onChange}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value="daily">Diario</MenuItem>
      <MenuItem value="weekly">Semanal</MenuItem>
      <MenuItem value="monthly">Mensual</MenuItem>
    </Select>
  </FormControl>
);

TimePeriodSelect.propTypes = {
  currentSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TimePeriodSelect.defaultProps = {
  currentSelection: 'Diario',
};

export default TimePeriodSelect;
