import { gql } from '@apollo/client';

export const GET_STORE = gql`
  query GetStore(
    $id: ID!
  ) {
    restaurant(
      id: $id
    ) {
      id
      name
      short_address
      isOpen
      pickup_time
      allowsScheduling
      erpConfig
      logo {
        url
        id
        mime
      }
      open_hours {
        id
        day
        hours {
          start
          end
        }
      }
      banner {
        id
        desktop {
          id
          url
          formats
          ext
        }
        mobile {
          id
          url
          formats
          ext
        }
      }
      in_store_menus {
        id
        name
        file {
          id
          mime
          url
        }
      }
      deliveryCopay
      deliveryPickupDelay
      googleTagManagerId
      facebookDomainVerification
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation UpdateStore(
    $id: ID!
    $name: String
    $short_address: String
    $isOpen: Boolean
    $allowsScheduling: Boolean
    $pickup_time: Int
    $open_hours: [ID]
    $products: JSON
    $erpConfig: JSON
    $logo: ID,
    $inStoreMenus: [ID],
    $deliveryCopay: Float,
    $deliveryPickupDelay: Int,
    $googleTagManagerId: String,
    $facebookDomainVerification: String,
  ) {
    updateRestaurant(
      input: {
        where: { id: $id },
        data: {
          name: $name,
          short_address: $short_address,
          isOpen: $isOpen,
          allowsScheduling: $allowsScheduling,
          pickup_time: $pickup_time,
          open_hours: $open_hours,
          products: $products,
          erpConfig: $erpConfig,
          logo: $logo,
          in_store_menus: $inStoreMenus,
          deliveryCopay: $deliveryCopay,
          deliveryPickupDelay: $deliveryPickupDelay,
          googleTagManagerId: $googleTagManagerId,
          facebookDomainVerification: $facebookDomainVerification,
        }
      }
    ) {
      restaurant {
        id
        deliveryCopay
        deliveryPickupDelay
        googleTagManagerId
        facebookDomainVerification
      }
    }
  }
`;

export const UPDATE_OPEN_HOUR = gql`
  mutation UpdateOpenHour(
    $id: ID!
    $day: ENUM_OPENHOUR_DAY
    $hours: [editComponentMainDayInput]
  ) {
    updateOpenHour(
      input: {
        where: { id: $id },
        data: {
          day: $day,
          hours: $hours,
        }
      }
    ) {
      openHour {
        id
      }
    }
  }
`;

export const CREATE_OPEN_HOUR = gql`
  mutation CreateOpenHour(
    $day: ENUM_OPENHOUR_DAY
    $hours: [ComponentMainDayInput]
  ) {
    createOpenHour(
      input: {
        data: {
          day: $day,
          hours: $hours,
        }
      }
    ) {
      openHour {
        id
      }
    }
  }
`;

export const DELETE_OPEN_HOUR = gql`
  mutation DeleteOpenHour(
    $id: ID!
  ) {
    deleteOpenHour(
      input: {
        where: { id: $id },
      }
    ) {
      openHour {
        id
      }
    }
  }
`;

export const CREATE_IN_STORE_MENU = gql`
  mutation CreateInStoreMenu(
    $name: String!
    $file: ID!
  ) {
    createInStoreMenu(
      input: {
        data: {
          name: $name,
          file: $file,
        }
      }
    ) {
      inStoreMenu {
        id
      }
    }
  }
`;

export const UPDATE_IN_STORE_MENU = gql`
  mutation UpdateInStoreMenu(
    $id: ID!
    $name: String
    $file: ID!
  ) {
    updateInStoreMenu(
      input: {
        where: { id: $id },
        data: {
          name: $name,
          file: $file,
        }
      }
    ) {
      inStoreMenu {
        id
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories($storeId: ID!){
    getCategories(restaurantId: $storeId)
  }
`;

export const GET_ITEMS = gql`
  query GetItems($storeId: ID!){
    getItems(restaurantId: $storeId)
  }
`;

export const UPDATE_BANNER = gql`
  mutation UpdateBanner(
    $bannerId: ID!,
    $desktop: [ID],
    $mobile: [ID]
  ) {
    updateBanner(
      input: {
        where: { id: $bannerId }
        data: {
          desktop: $desktop,
          mobile: $mobile,
        }
      }
    ) {
      banner {
        id
        desktop {
          id
          url
        }
        mobile {
          id
          url
        }
      }
    }
  }
`;

export const CONNECT_TOTEAT = gql`
  query ConnectToteat(
    $restaurantId: ID!
    $xir: String!,
    $xil: String!,
    $xiu: String!,
    $xapitoken: String!,
    $categoriesHidden: [String]
  ) {
    connectToteat(
      restaurantId: $restaurantId,
      xir: $xir,
      xil: $xil,
      xiu: $xiu,
      xapitoken: $xapitoken,
      categoriesHidden: $categoriesHidden,
    )
  }
`;
