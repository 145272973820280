/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

const useStyles = makeStyles({
  imgContainer: {
    position: 'relative',
    padding: '1rem 0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#373736',
    cursor: 'pointer',
  },
  actionsContainer: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    display: 'flex',
    width: 70,
    justifyContent: 'space-between',
  },
  switcher: {
    position: 'absolute',
    top: '45%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    zIndex: 10,
    '&&:hover': {
      backgroundColor: 'black',
    },
  },
  leftSwitcher: {
    left: '1rem',
  },
  rightSwitcher: {
    transform: 'rotate(180deg)',
    right: '1rem',
  },
  customIconButton: {
    cursor: 'pointer',
    padding: 3,
  },
});

const fileTypeIs = (file, type) => {
  const fileObject = file.file || file;
  return fileObject?.mime?.includes(type) || fileObject?.type?.includes(type)
};

const UniqueFileUpload = ({ label, onChange, initialValue, accept, placeholder }) => {
  const classes = useStyles();
  const [file, setFile] = useState(initialValue);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (fileTypeIs(file, 'image')) {
      file.url ? setPreview(file.url) : setPreview(URL.createObjectURL(file));
    }
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(preview);
  }, [file]);

  // useEffect(() => {
  //   setFile(initialValue);
  // }, [initialValue]);

  const handleSetFile = (event) => {
    const { files } = event.target;
    setFile(files[0]);
    onChange(files[0]);
  };

  const deleteImage = () => {
    setFile(null);
    setPreview(null);
    onChange(null);
  };

  const Input = () => (
    <input
      style={{ display: 'none', width: '100%', height: '100%' }}
      type="file"
      id={`${label}.${file?.id}`}
      accept={accept}
      onChange={handleSetFile}
    />
  )

  const getPreview = () => {
    // .url is when file already has been uploaded to server, .file is when it lives in the browser
    if (file?.url || file?.name) {
      let name = file.name;
      if (file.url) {
        const splittedUrl = file.url.split('/');
        name = splittedUrl[splittedUrl.length - 1];
      }
      return (
        <div className={classes.imgContainer}>
          {preview && <img style={{ width: 100 }} src={preview} alt="preview" />}
          {fileTypeIs(file, 'pdf') && (
            <Typography variant="body2" style={{ color: 'white' }}>
              {name}
            </Typography>
          )}
          <div className={classes.actionsContainer}>
            <Paper>
              <Input />
              <label htmlFor={`${label}.${file.id}`} style={{ display: 'flex' }}>
                <Edit color="primary" className={classes.customIconButton} />
              </label>
            </Paper>
            <Paper>
              <IconButton onClick={deleteImage} style={{ padding: 3 }}>
                <Delete color="error" />
              </IconButton>
            </Paper>
          </div>
        </div>
      )
    }
    let text = placeholder || 'Clickea para seleccionar el archivo'
    return (
      <label htmlFor={`${label}.${file?.id}`}>
        <div className={classes.imgContainer}>
          <Typography variant="body2" style={{ color: 'white' }}>
            {text}
          </Typography>
        </div >
      </label >
    )
  }

  return (
    <>
      <Input />
      <Typography>{label}</Typography>
      {getPreview()}
    </>
  );
};

UniqueFileUpload.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }),
  accept: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

UniqueFileUpload.defaultProps = {
  label: '',
  initialValue: [],
};

export default UniqueFileUpload;
