import { gql } from '@apollo/client';

export const GET_PROMOTIONAL_CODES = gql`
  query GetPromotionalCodes(
    $restaurantId: ID!
  ) {
    promotionalCodes(where: { restaurant: $restaurantId }) {
      id
      code
      multipleCodes
      name
      type
      rule
      remaining
      total
      isActive
      isStackable
      isUnique
      isNewUsersOnly
      isLimited
      isMultiple
      items {
        id
        name
      }
      categories {
        id
        name
      }
      itemsJSON
      categoriesJSON
      created_at
    }
  }
`;

export const UPDATE_PROMOTIONAL_CODE = gql`
  mutation UpdatePromotionalCode(
    $id: ID!
    $code: String
    $multipleCodes: JSON
    $type: ENUM_PROMOTIONALCODES_TYPE
    $rule: String
    $total: Int
    $remaining: Int
    $name: String
    $isActive: Boolean
    $isStackable: Boolean
    $isMultiple: Boolean
    $isUnique: Boolean
    $isNewUsersOnly: Boolean
    $isLimited: Boolean
    $items: [ID]
    $categories: [ID]
    $itemsJSON: JSON
    $categoriesJSON: JSON
  ) {
    updatePromotionalCode(
      input: {
        where: { id: $id },
        data: {
          code: $code,
          type: $type,
          multipleCodes: $multipleCodes,
          rule: $rule,
          total: $total,
          remaining: $remaining,
          name: $name,
          isActive: $isActive,
          isStackable: $isStackable,
          isMultiple: $isMultiple,
          isUnique: $isUnique,
          isNewUsersOnly: $isNewUsersOnly,
          isLimited: $isLimited,
          items: $items,
          categories: $categories,
          itemsJSON: $itemsJSON,
          categoriesJSON: $categoriesJSON,
        }
      }
    ) {
      promotionalCode {
        id
      }
    }
  }
`;

export const CREATE_PROMOTIONAL_CODE = gql`
  mutation CreatePromotionalCode(
    $code: String
    $multipleCodes: JSON
    $type: ENUM_PROMOTIONALCODES_TYPE!
    $rule: String
    $total: Int
    $remaining: Int
    $name: String
    $isActive: Boolean
    $isStackable: Boolean
    $isMultiple: Boolean
    $isUnique: Boolean
    $isNewUsersOnly: Boolean
    $isLimited: Boolean
    $items: [ID]
    $categories: [ID]
    $restaurant: ID
    $itemsJSON: JSON
    $categoriesJSON: JSON
  ) {
    createPromotionalCode(
      input: {
        data: {
          code: $code,
          type: $type,
          rule: $rule,
          total: $total,
          remaining: $remaining,
          name: $name,
          isActive: $isActive,
          isStackable: $isStackable,
          isMultiple: $isMultiple,
          isUnique: $isUnique,
          isNewUsersOnly: $isNewUsersOnly,
          isLimited: $isLimited,
          items: $items,
          categories: $categories,
          restaurant: $restaurant,
          itemsJSON: $itemsJSON,
          categoriesJSON: $categoriesJSON,
          multipleCodes: $multipleCodes,
        }
      }
    ) {
      promotionalCode {
        id
      }
    }
  }
`;
