import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Typography,
  TextField,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Switch,
} from '@material-ui/core';
import { Add, Delete, ExpandMore } from '@material-ui/icons';
import { useMainStyles } from '../../styles';
import { orderOfDay } from '../../helpers/date';
import { translateDay } from '../../helpers/i18';
import { capitalize } from '../../helpers/strings';
import { TimeSelect } from '../inputs';
import { TwoButtonAlert } from '../dialogs';
import { UPDATE_DELIVERY_TIME } from '../../gql/deliveryZones';

const DeliveryZone = ({ deliveryZone, liftDeliveryZone, handleRemove }) => {
  const classes = useMainStyles();
  const [updateDeliveryTime] = useMutation(UPDATE_DELIVERY_TIME);
  const [_deliveryZone, setDeliveryZone] = useState(deliveryZone);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);

  useEffect(() => {
    setDeliveryZone(deliveryZone);
  }, [deliveryZone]);

  const handleTextChange = (e) => {
    const { name: _name, value } = e.target;
    const newDeliveryZone = { ..._deliveryZone, [_name]: value };
    setDeliveryZone(newDeliveryZone);
    liftDeliveryZone(newDeliveryZone);
  };

  const handleBooleanChange = (e) => {
    const { name: _name, checked } = e.target;
    const newDeliveryZone = { ..._deliveryZone, [_name]: checked };
    setDeliveryZone(newDeliveryZone);
    liftDeliveryZone(newDeliveryZone);
  };

  const handleNewHourAndTime = (deliveryTime) => {
    const newDeliveryZone = { ..._deliveryZone };
    const newDeliveryTime = { ...deliveryTime, modified: true };
    const tempId = newDeliveryTime.hours_and_time.reduce((ht, acc) => acc + ht.id, '');
    let start = '09:00:00.000';
    const currentHoursAndTimes = deliveryTime.hours_and_time.length;
    if (currentHoursAndTimes > 0) {
      start = deliveryTime.hours_and_time[currentHoursAndTimes - 1].end;
    }
    newDeliveryTime.hours_and_time = [
      ...newDeliveryTime.hours_and_time,
      { start, end: '00:00:00.000', id: tempId, isNew: true },
    ];
    const index = newDeliveryZone.delivery_times.findIndex((dt) => dt.id === deliveryTime.id);
    const newDeliveryTimes = [...newDeliveryZone.delivery_times];
    newDeliveryTimes[index] = newDeliveryTime;
    newDeliveryZone.delivery_times = newDeliveryTimes;
    setDeliveryZone(newDeliveryZone);
    liftDeliveryZone(newDeliveryZone);
  };

  const deleteHourFromState = (newDeliveryTime) => {
    const newDeliveryZone = { ..._deliveryZone };
    const index = newDeliveryZone.delivery_times.findIndex((dt) => dt.id === newDeliveryTime.id);
    const newDeliveryTimes = [...newDeliveryZone.delivery_times];
    newDeliveryTimes[index] = newDeliveryTime;
    newDeliveryZone.delivery_times = newDeliveryTimes;
    setDeliveryZone(newDeliveryZone);
    liftDeliveryZone(newDeliveryZone);
  };

  const deleteOldHour = () => {
    setDeleteLoading(true);
    updateDeliveryTime({
      variables: {
        id: selectedDeliveryTime.id,
        hours_and_time: selectedDeliveryTime.hours_and_time.map((ht) => {
          const { start, end, time, id: _id } = ht;
          return ({ start, end, time, id: _id });
        }),
      },
    })
      .then(() => {
        deleteHourFromState(selectedDeliveryTime);
        setDialogOpen(false);
        setDeleteLoading(false);
        setSelectedDeliveryTime(null);
      })
      .catch((error) => {
        console.error(error);
        setDialogOpen(false);
        setDeleteLoading(false);
      });
  };

  const handleRemoveHour = (deliveryTime, hour) => {
    const newHoursAndTime = deliveryTime.hours_and_time.filter((ht) => ht.id !== hour.id);
    const newDeliveryTime = { ...deliveryTime, hours_and_time: newHoursAndTime };
    if (!hour.isNew) {
      setSelectedDeliveryTime(newDeliveryTime);
      setDialogOpen(true);
      return;
    }
    deleteHourFromState(newDeliveryTime);
  };

  // type can be start or end
  const handleHourChange = (deliveryTime, hourWithTime, newHour, type) => {
    const newDeliveryZone = { ..._deliveryZone };
    const newHoursAndTime = deliveryTime.hours_and_time.map((ht) => {
      if (ht.id === hourWithTime.id) return { ...ht, [type]: `${newHour}:00.000` };
      return ht;
    });
    const newDeliveryTime = { ...deliveryTime, hours_and_time: newHoursAndTime, modified: true };
    newDeliveryZone.delivery_times = newDeliveryZone.delivery_times.map((dt) => {
      if (dt.id === deliveryTime.id) return newDeliveryTime;
      return dt;
    });
    setDeliveryZone(newDeliveryZone);
    liftDeliveryZone(newDeliveryZone);
  };

  const onTimeChange = (deliveryTime, hourWithTime, newTime) => {
    const newDeliveryZone = { ..._deliveryZone };
    const newHoursAndTime = deliveryTime.hours_and_time.map((ht) => {
      if (ht.id === hourWithTime.id) return { ...ht, time: newTime };
      return ht;
    });
    const newDeliveryTime = { ...deliveryTime, hours_and_time: newHoursAndTime, modified: true };
    newDeliveryZone.delivery_times = newDeliveryZone.delivery_times.map((dt) => {
      if (dt.id === deliveryTime.id) return newDeliveryTime;
      return dt;
    });
    setDeliveryZone(newDeliveryZone);
    liftDeliveryZone(newDeliveryZone);
  };

  const {
    id,
    name,
    isActive,
    delivery_cost: deliveryCost,
    minimum_order: minimumOrder,
    delivery_times: deliveryTimes,
  } = _deliveryZone;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography><b>Zona {id}</b></Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="flex-end" justify="space-between">
            <Grid
              item
              xs={6}
              md={2}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1rem' }}
            >
              <Typography>{isActive ? 'Activa' : 'Inactiva'}</Typography>
              <Switch
                checked={isActive}
                name="isActive"
                onChange={handleBooleanChange}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.subSubSection}>
              <Typography>Nombre</Typography>
              <TextField
                name="name"
                value={name}
                onChange={handleTextChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.subSubSection}>
              <Typography>Monto mínimo</Typography>
              <TextField
                name="minimum_order"
                value={minimumOrder}
                onChange={handleTextChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.subSubSection}>
              <Typography>Precio despacho</Typography>
              <TextField
                name="delivery_cost"
                value={deliveryCost}
                onChange={handleTextChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.subSubSection}>
              <Button
                onClick={handleRemove}
                fullWidth
                variant="outlined"
                style={{ borderColor: 'red', height: 39 }}
                endIcon={<Delete color="error" />}
              >
                <Typography color="error">Eliminar</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel-zone-${id}-content`}
              id={`panel-zone-${id}-header`}
            >
              <Typography gutterBottom><b>Tiempos de entrega</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {deliveryTimes
                  .sort((a, b) => orderOfDay[a.day] - orderOfDay[b.day])
                  .map((dt) => (
                    <Grid item xs={12} md={6} lg={6} key={dt.id} className={classes.section}>
                      <Grid container style={{ marginBottom: '0.25rem' }}>
                        <Grid item xs={8} md={7}>
                          <Typography>{capitalize(translateDay(dt.day, 'ES'))}</Typography>
                        </Grid>
                        <Grid item xs={4} md={5}>
                          <Typography>Minutos</Typography>
                        </Grid>
                      </Grid>
                      {dt.hours_and_time.length > 0
                        ? dt.hours_and_time.map((hourWithTime) => (
                          <Grid container key={hourWithTime.id} alignItems="center" className={classes.subSubSection}>
                            <Grid item xs={8} md={7}>
                              <Grid container alignItems="center">
                                <TimeSelect
                                  currentSelection={hourWithTime.start.substring(0, 5)}
                                  onChange={(hour) => handleHourChange(dt, hourWithTime, hour, 'start')}
                                />
                                <Typography align="center" style={{ margin: '0 5px' }}>-</Typography>
                                <TimeSelect
                                  currentSelection={hourWithTime.end.substring(0, 5)}
                                  onChange={(hour) => handleHourChange(dt, hourWithTime, hour, 'end')}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={4} md={5}>
                              <Grid container alignItems="center">
                                <Grid item xs={8}>
                                  <TextField
                                    name="time"
                                    value={hourWithTime.time}
                                    onChange={(e) => onTimeChange(dt, hourWithTime, e.target.value)}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <IconButton onClick={() => handleRemoveHour(dt, hourWithTime)}>
                                    <Delete color="error" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                        : (
                          <Typography variant="body2">
                            No tienes horarios de despacho el día {translateDay(dt.day, 'ES')}
                          </Typography>
                        )}
                      <Grid container>
                        <Button
                          color="primary"
                          startIcon={<Add />}
                          onClick={() => handleNewHourAndTime(dt)}
                        >
                          Agregar horario
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <TwoButtonAlert
        open={dialogOpen}
        title="¡Esta acción es irreversible!"
        message="¿Estás seguro que quieres eliminar este horario?"
        loading={deleteLoading}
        onAccept={deleteOldHour}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

DeliveryZone.propTypes = {
  deliveryZone: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    delivery_cost: PropTypes.number.isRequired,
    radius: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    minimum_order: PropTypes.number.isRequired,
    delivery_times: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        day: PropTypes.string.isRequired,
        hours_and_time: PropTypes.arrayOf(
          PropTypes.shape({
            start: PropTypes.string.isRequired,
            end: PropTypes.string.isRequired,
            time: PropTypes.number.isRequired,
          }).isRequired,
        ),
      }).isRequired,
    ),
  }).isRequired,
  liftDeliveryZone: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
};

DeliveryZone.defaultProps = {
  handleRemove: () => { },
};

export default DeliveryZone;
