/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { hoursBy15 } from '../../constants/dateTime';

// minHour is used for ranges in times. minHour is the start time
const TimeSelect = ({ currentSelection, onChange, className, style, minHour, maxHour }) => (
  <Select
    onChange={(event) => onChange(event.target.value)}
    value={currentSelection}
    variant="outlined"
    className={className}
    style={{ minWidth: 90, ...style }}
  >
    {hoursBy15.filter((hour) => hour >= minHour && hour <= maxHour).map((hour) => (
      <MenuItem key={hour} value={hour}>{hour}</MenuItem>
    ))}
  </Select>
);

TimeSelect.propTypes = {
  currentSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.object,
  style: PropTypes.object,
  minHour: PropTypes.string,
  maxHour: PropTypes.string,
};

TimeSelect.defaultProps = {
  currentSelection: '09:00',
  minHour: '00:00',
  maxHour: '23:45',
};

export default TimeSelect;
