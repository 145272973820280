import React from 'react';
import { Typography } from '@material-ui/core';

const ErrorPage = () => (
  <>
    <Typography variant="h2">
      Lo sentimos
    </Typography>
    <Typography variant="h6">
      Ocurrió un error
    </Typography>
  </>
);

export default ErrorPage;
