import { gql } from "@apollo/client";

export const GET_UBER_DELIVERIES = gql`
  query GetUberDeliveries(
    $restaurantId: ID!
  ) {
    uberDeliveries(where: { order: { restaurant: $restaurantId }, status: ["pending", "ongoing", "pickup", "pickup_complete", "dropoff"]  }) {
      id
      dropoff
      status
      courier
      manifest_items
      delivery_id
      fee
      created_at
      uuid
      pickup_eta
    }
  }
`;

export const CANCEL_UBER_DELIVERY = gql`
  mutation cancelUberDelivery(
    $uberId: String!,
  ) {
  	cancelUberDelivery(
      uber_id: $uberId,
    ) {
      ...on GraphqlError {
        status
        code
        message
      }
      ...on UberDelivery {
        id
      }
    }
  }
`