import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Container, Grid, InputAdornment, LinearProgress, TextField, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { MainContext } from '../context/MainContext';
import { GET_UBER_DELIVERIES } from '../gql/uberDeliveries';
import { useMainStyles } from '../styles';
import ErrorPage from './ErrorPage';
import UberDeliveryCard from '../components/uberDelivery/Card';
import { OneButtonAlert } from '../components/dialogs';
import { UPDATE_STORE } from '../gql/store';

const copayInstructions = `Este porcentaje se descontará del precio de delivery que pagará el
cliente. Si uber cobra $2.000 y el copago es 50%, el cliente paga $1.000 y el restaurante $1.000`;

const delayInstructions = `Este es el tiempo mínimo para que llegue el repartidor al local.
Si son 25 minutos y se hace un pedido a las 14:00, lo antes que llegará el repartidor es a las 14:25`;

const UberDeliveries = () => {
  const classes = useMainStyles();
  const { store, setStore } = useContext(MainContext);
  const [copay, setCopay] = useState(store.deliveryCopay ? store.deliveryCopay * 100 : 0);
  const [copayError, setCopayError] = useState('');
  const [copayLoading, setCopayLoading] = useState(false);
  const [deliveryPickupDelay, setDeliveryPickupDelay] = useState(
    store.deliveryPickupDelay ? store.deliveryPickupDelay : 0
  );
  const [deliveryPickupDelayError, setDeliveryPickupDelayError] = useState('');
  const [deliveryPickupDelayLoading, setDeliveryPickupDelayLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const { data, loading, error, refetch } = useQuery(GET_UBER_DELIVERIES, {
    variables: { restaurantId: store.id },
    pollInterval: 120000
  });
  const [updateStore] = useMutation(UPDATE_STORE);

  useEffect(() => {
    if (store) {
      setCopay(store.deliveryCopay ? store.deliveryCopay * 100 : 0);
      setDeliveryPickupDelay(store.deliveryPickupDelay ? store.deliveryPickupDelay : 0);
    }
  }, [store])


  const handleUpdateCopay = async () => {
    setCopayLoading(true);
    const { data: { updateRestaurant: { restaurant } } } = await updateStore({
      variables: {
        id: store.id,
        deliveryCopay: copay / 100,
      }
    })
    setStore({ ...store, ...restaurant })
    setAlertOpen(true);
    setCopayLoading(false);
  }

  const handleUpdateDeliveryPickupDelay = async () => {
    setDeliveryPickupDelayLoading(true);
    const { data: { updateRestaurant: { restaurant } } } = await updateStore({
      variables: {
        id: store.id,
        deliveryPickupDelay: parseInt(deliveryPickupDelay, 10),
      }
    })
    setStore({ ...store, ...restaurant })
    setAlertOpen(true);
    setDeliveryPickupDelayLoading(false);
  }

  const handleCopay = ({ target: { value } }) => {
    if (value > 100) {
      setCopayError('El copago no puede superar 100%');
      return;
    }
    if (value < 0) {
      setCopayError('El copago no puede ser menor a 0%');
      return;
    }
    setCopayError('');
    setCopay(value);
  }

  const handleDeliveryPickupDelay = ({ target: { value } }) => {
    if (value < 0) {
      setDeliveryPickupDelayError('La espera no puede ser menor a 0');
      return;
    }
    setDeliveryPickupDelayError('');
    setDeliveryPickupDelay(value);
  }

  if (loading || !data) return <LinearProgress />;
  if (error) return <ErrorPage />;

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center" className={classes.section}>
            <Grid item xs={12}>
              <Typography variant="h6">Configuración de copago</Typography>
            </Grid>
            <Grid item xs={12} className={classes.subSubSection}>
              <Typography variant="body2">
                {copayInstructions}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.subSubSection}>
              <TextField
                type="number"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  ),
                }}
                error={Boolean(copayError)}
                helperText={copayError}
                value={copay}
                onChange={handleCopay}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleUpdateCopay}
                variant="outlined"
                color="primary"
                disabled={copayLoading}
                startIcon={copayLoading ? <CircularProgress size={14} /> : null}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center" className={classes.section}>
            <Grid item xs={12}>
              <Typography variant="h6">Minutos de espera para retiro de pedidos</Typography>
            </Grid>
            <Grid item xs={12} className={classes.subSubSection}>
              <Typography variant="body2">
                {delayInstructions}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.subSubSection}>
              <TextField
                type="number"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Min
                    </InputAdornment>
                  ),
                }}
                error={Boolean(deliveryPickupDelayError)}
                helperText={deliveryPickupDelayError}
                value={deliveryPickupDelay}
                onChange={handleDeliveryPickupDelay}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleUpdateDeliveryPickupDelay}
                variant="outlined"
                color="primary"
                disabled={deliveryPickupDelayLoading}
                startIcon={deliveryPickupDelayLoading ? <CircularProgress size={14} /> : null}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h6">Pedidos en curso</Typography>
      <Grid container spacing={2} justifyContent="center">
        {data.uberDeliveries.length > 0 ? (
          <>
            {[...data.uberDeliveries].sort((a, b) => a.created_at < b.created_at ? 1 : -1).map((uberDelivery) => (
              <Grid item xs={12} key={uberDelivery.id}>
                <UberDeliveryCard refetch={refetch} uberDelivery={uberDelivery} />
              </Grid>
            ))}
          </>
        ) : (
          <Typography variant="h6">En estos momentos no hay ningún pedido en espera</Typography>
        )}
        { }
      </Grid>
      <OneButtonAlert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title="Cambios realizados con éxito"
      />
    </Container>
  );
}

export default UberDeliveries;