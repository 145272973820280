/* eslint-disable import/prefer-default-export */
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stringToMultiline = (value) => {
  if (typeof value === "string" || value instanceof String) {
    return value
      .replace(/[^a-zA-Z0-9\n ]/g, " ")
      .split(" ")
      .join("\n")
      .replace(/(\r\n|\r|\n){2}/g, "$1")
      .replace(/(\r\n|\r|\n){3,}/g, "$1\n");
  } else {
    console.log("Error al parsear multilinea, el valor no es string");
    return value;
  }
};
