import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  TextField,
  Paper,
  Container,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { validEmail } from '../helpers/validators';
import { login } from '../apiCalls/strapi';
import { MainContext } from '../context/MainContext';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: '1rem',
    width: '100%'
  },
  buttonContainer: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
});

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const { setUser, setStore } = useContext(MainContext);

  const [formData, setFormData] = useState({
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: { ...oldFormData[name], value },
    }));
  };

  const setFormError = (name, _error) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: { ...oldFormData[name], _error },
    }));
  };

  const handleLogin = () => {
    const { email, password } = formData;
    if (!validEmail(email.value)) {
      setFormError('email', 'Email inválido');
      return;
    }
    setLoading(true);
    setFormError('email', '');
    login(email.value, password.value)
      .then(async (response) => {
        const { data: { jwt, user } } = response;
        if (user.role.type !== 'restaurant_owner') {
          setError('Este usuario no es administrador de un local');
          setLoading(false);
        } else {
          const jsonUser = {
            jwt,
            ...user,
          };
          await setUser(jsonUser);
          await setStore(jsonUser.restaurants[0]);
          history.push('/app/store-information');
        }
      })
      .catch((error) => {
        console.error(error);
        setError('Credenciales inválidas');
        setLoading(false);
      });
  };

  const { email, password } = formData;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center">Inicia Sesión</Typography>
        <div style={{ marginTop: '2rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <Typography>Email</Typography>
            <TextField
              name="email"
              value={email.value}
              error={Boolean(email.error)}
              helperText={email.error}
              onChange={onChange}
              type="email"
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Typography>Contraseña</Typography>
            <TextField
              name="password"
              value={password.value}
              error={Boolean(password.error)}
              helperText={password.error}
              onChange={onChange}
              type="password"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {Boolean(error) && (
            <Typography
              variant="caption"
              color="error"
              align="center"
              className={classes.errorMessage}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress size={16} /> : 'Ingresar'}
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default Login;
