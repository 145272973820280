import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from '../auth/PrivateRoute';
import Layout from '../components/layout';
import DeliveryZones from './DeliveryZones';
import UberDeliveries from './UberDeliveries';
import PromotionalCodes from './PromotionalCodes';
import StoreInformation from './StoreInformation';
import SalesAnalytics from './SalesAnalytics';
import UsersAnalytics from './UsersAnalytics';
import ERPIntegration from './ERPIntegration';
import ExternalToolsConfig from './ExternalToolsConfig';

const AppContainer = () => {
  const location = useLocation();
  if (location.pathname === '/app') return <Redirect to="/app/store-information" />;
  return (
    <Layout>
      <Switch>
        <PrivateRoute path="/app/store-information" component={StoreInformation} />
        <PrivateRoute path="/app/menu" component={ERPIntegration} />
        <PrivateRoute path="/app/promotional-codes" component={PromotionalCodes} />
        <PrivateRoute path="/app/delivery-zones" component={DeliveryZones} />
        <PrivateRoute path="/app/uber-deliveries" component={UberDeliveries} />
        <PrivateRoute path="/app/dashboard/sales" component={SalesAnalytics} />
        <PrivateRoute path="/app/dashboard/users" component={UsersAnalytics} />
        <PrivateRoute path="/app/external-tools/config" component={ExternalToolsConfig} />
      </Switch>
    </Layout>
  );
};

export default AppContainer;
