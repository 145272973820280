import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { formatMoney } from '../../../helpers/money';

const SalesPerPeriod = ({ sales }) => {
  const canvasRef = createRef();
  const [chartObject, setChartObject] = useState(null);

  useEffect(() => {
    if (!chartObject) {
      // eslint-disable-next-line no-new
      const newChartObject = new Chart(canvasRef.current, {
        type: 'bar',
        data: {
          labels: Object.keys(sales),
          datasets: [
            {
              label: 'Ventas',
              data: Object.values(sales),
              backgroundColor: '#4BB543',
            },
          ],
        },
        options: {
          tooltips: {
            callbacks: {
              label(tooltipItem) {
                return `$${formatMoney(tooltipItem.value)}`;
              },
            },
          },
        },
      });
      setChartObject(newChartObject);
    } else {
      chartObject.data.labels = Object.keys(sales);
      chartObject.data.datasets = [
        {
          label: 'Ventas',
          data: Object.values(sales),
          backgroundColor: '#4BB543',
        },
      ];
      chartObject.update();
    }
  }, [canvasRef, sales]);
  return (
    <canvas ref={canvasRef} />
  );
};

SalesPerPeriod.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sales: PropTypes.object.isRequired,
};

export default SalesPerPeriod;
