/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, Typography } from '@material-ui/core';
import { Add, ArrowBackIos, Delete } from '@material-ui/icons';

const useStyles = makeStyles({
  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '20rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#373736',
    cursor: 'pointer',
  },
  actionsContainer: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    display: 'flex',
    width: 70,
    justifyContent: 'space-between',
  },
  switcher: {
    position: 'absolute',
    top: '45%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    zIndex: 10,
    '&&:hover': {
      backgroundColor: 'black',
    },
  },
  leftSwitcher: {
    left: '1rem',
  },
  rightSwitcher: {
    transform: 'rotate(180deg)',
    right: '1rem',
  },
  customIconButton: {
    cursor: 'pointer',
    padding: 3,
  },
});

const MultipleImageUpload = ({ label, onChange, initialValue }) => {
  const classes = useStyles();
  const [images, setImages] = useState(initialValue);
  const [previews, setPreviews] = useState([]);
  const [previewIndex, setPreviewIndex] = useState(0);

  useEffect(() => {
    if (images.length === 0) {
      setPreviews([]);
      setPreviewIndex(0);
      return;
    }
    const objectUrls = [];
    images.forEach((image) => {
      if (!image.url) objectUrls.push(URL.createObjectURL(image));
      else objectUrls.push(image.url);
    });
    setPreviews(objectUrls);
    setPreviewIndex(objectUrls.length - 1);
    // free memory when ever this component is unmounted
    // eslint-disable-next-line consistent-return
    return () => previews.forEach((preview) => URL.revokeObjectURL(preview));
  }, [images]);

  useEffect(() => {
    setImages(initialValue);
  }, [initialValue]);

  const containsFile = (array, file) => {
    for (let i = 0; i < array.length; i += 1) {
      const arrayFile = array[i];
      if (file.name === arrayFile.name
        && file.lastModified === arrayFile.lastModified
        && file.size === arrayFile.size
      ) {
        return true;
      }
    }
    return false;
  };

  const getNewImages = (oldImages, files) => {
    const newImages = [];
    files.forEach((file) => {
      if (!containsFile(oldImages, file) && !containsFile(newImages, file)) {
        newImages.push(file);
      }
    });
    return newImages;
  };

  const addImages = (event) => {
    const { files } = event.target;
    const arrayFiles = Array.from(files);
    const newImages = getNewImages(images, arrayFiles);
    const totalImages = [...images, ...newImages];
    setImages(totalImages);
    onChange(totalImages);
  };

  const nextPreview = () => {
    const newPreviewIndex = (previewIndex + 1) % previews.length;
    setPreviewIndex(newPreviewIndex);
  };

  const previousPreview = (previewsParam = previews) => {
    const temp = previewIndex - 1;
    const { length } = previewsParam;
    const newPreviewIndex = ((temp % length) + length) % length;
    setPreviewIndex(newPreviewIndex);
  };

  const deleteImage = () => {
    const newImages = [
      ...images.slice(0, previewIndex),
      ...images.slice(previewIndex + 1, images.length),
    ];
    const newPreviews = [
      ...previews.slice(0, previewIndex),
      ...previews.slice(previewIndex + 1, previews.length),
    ];
    setImages(newImages);
    setPreviews(newPreviews);
    previousPreview(newPreviews);
    onChange(newImages);
  };

  return (
    <>
      <input
        style={{ display: 'none', width: '100%', height: '100%' }}
        type="file"
        multiple
        id={label}
        accept="image/*"
        onChange={addImages}
      />
      <Typography>{label} {previews.length > 0 ? `(${previewIndex + 1}/${previews.length})` : ''}</Typography>
      {previews.length > 0 ? (
        <div className={classes.imgContainer}>
          <img style={{ width: '100%' }} src={previews[previewIndex]} alt="preview" />
          {images.length > 0 && (
            <div className={classes.actionsContainer}>
              <Paper>
                <input
                  style={{ display: 'none', width: '100%', height: '100%' }}
                  type="file"
                  multiple
                  id={`${label}-2`}
                  accept="image/*"
                  onChange={addImages}
                />
                <label htmlFor={`${label}-2`} style={{ display: 'flex' }}>
                  <Add color="primary" className={classes.customIconButton} />
                </label>
              </Paper>
              <Paper>
                <IconButton onClick={deleteImage} style={{ padding: 3 }}>
                  <Delete color="error" />
                </IconButton>
              </Paper>
            </div>
          )}
          {images.length > 1 && (
            <>
              <IconButton className={`${classes.switcher} ${classes.leftSwitcher}`} onClick={(e) => previousPreview()}>
                <ArrowBackIos size={30} />
              </IconButton>
              <IconButton className={`${classes.switcher} ${classes.rightSwitcher}`} onClick={(e) => nextPreview()}>
                <ArrowBackIos size={30} />
              </IconButton>
            </>
          )}
        </div>
      )
        : (
          <label htmlFor={label}>
            <div className={classes.imgContainer}>
              <Typography variant="body2" style={{ color: 'white' }}>Clickea para seleccionar las imágenes</Typography>
            </div>
          </label>
        )}
    </>
  );
};

MultipleImageUpload.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  })),
};

MultipleImageUpload.defaultProps = {
  label: 'Escoge las imágenes',
  initialValue: [],
};

export default MultipleImageUpload;
