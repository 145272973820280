/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, NavLink } from 'react-router-dom';
import { Menu as MenuIcon, Person, Store } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
  },
  toolbar: {
    display: 'flex',
  },
  iconButton: {
    paddingTop: 5,
  },
  menuIcon: {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  logoContainer: {
    paddingLeft: '1rem',
  },
  linksContainer: {
    marginLeft: '5rem',
  },
  link: {
    marginRight: '2rem',
  },
  leftcontainer: {
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
  },
  rightContainer: {
    flex: 0.2,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  storeName: {
    marginRight: '2rem',
  },
  paper: {
    borderRadius: '50%',
    boxShadow: `0px 4px 19px -7px ${theme.palette.primary.main}`,
    marginRight: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
  },
  logo: {
    width: 50,
    [theme.breakpoints.down('sm')]: {
      width: 30,
    },
  },
}));

const Header = ({ toggleDrawer, logout, user, store, handleStoreChange }) => {
  const classes = useStyles();
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const openMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setUserAnchorEl(null);
  };

  const RestaurantSwitcher = () => {
    const [storeAnchorEl, setStoreAnchorEl] = useState(null);
    const openStoreMenu = (event) => {
      setStoreAnchorEl(event.currentTarget);
    };

    const closeStoreMenu = () => {
      setStoreAnchorEl(null);
    };

    const handleSelectStore = (restaurant) => {
      handleStoreChange(restaurant);
      closeStoreMenu();
    };
    if (user.restaurants.length <= 1) return null;
    return (
      <Paper className={classes.paper}>
        <IconButton onClick={openStoreMenu} style={{ padding: 5 }}>
          <Store color="primary" />
        </IconButton>
        <Menu
          id="store-menu"
          anchorEl={storeAnchorEl}
          keepMounted
          open={Boolean(storeAnchorEl)}
          onClose={closeStoreMenu}
        >
          {user.restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} onClick={() => handleSelectStore(restaurant)}>
              {restaurant.name}
            </MenuItem>
          ))}
        </Menu>
      </Paper>
    );
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftcontainer}>
          <Hidden lgUp>
            <IconButton onClick={toggleDrawer} style={{ padding: 5 }}>
              <MenuIcon color="primary" />
            </IconButton>
          </Hidden>
          {store.logo && (
            <div className={classes.logoContainer}>
              <img
                src={store.logo.url}
                alt="logo"
                className={classes.logo}
              />
            </div>
          )}
        </div>
        <div className={classes.rightContainer}>
          <Hidden mdDown>
            {user && (
              <>
                <Typography color="primary" className={classes.storeName}>{store.name}</Typography>
                <RestaurantSwitcher />
                <Paper className={classes.paper}>
                  <IconButton onClick={openMenu} style={{ padding: 5 }}>
                    <Person color="primary" />
                  </IconButton>
                  <Menu
                    id="user-menu"
                    anchorEl={userAnchorEl}
                    keepMounted
                    open={Boolean(userAnchorEl)}
                    onClose={closeMenu}
                  >
                    <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                  </Menu>
                </Paper>
              </>
            )}
          </Hidden>
          <Hidden lgUp>
            <RestaurantSwitcher />
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    restaurants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }).isRequired,
  store: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  setStore: PropTypes.func.isRequired,
};

export default Header;
