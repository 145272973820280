import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core';

const TwoButtonAlert = ({ open, onClose, message, title, onAccept, loading }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="secondary"
        disabled={loading}
      >
        Cancelar
      </Button>
      <Button
        onClick={onAccept}
        color="primary"
        disabled={loading}
      >
        {loading ? <CircularProgress size={16} /> : 'Aceptar'}
      </Button>
    </DialogActions>
  </Dialog>
);

TwoButtonAlert.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

TwoButtonAlert.defaultProps = {
  message: '',
  loading: false,
};

export default TwoButtonAlert;
