/* eslint-disable import/prefer-default-export */
const dayToSpanish = {
  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábado',
  sunday: 'domingo',
};

const promotionalCodeTypeToSpanish = {
  checkDiscount: 'Descuento todos los productos',
  categoryDiscount: 'Descuento por categoría',
  itemDiscount: 'Descuento por producto',
  checkYxZ: 'YxZ en todos los productos',
  categoryYxZ: 'YxZ por categoría',
  itemYxZ: 'YxZ por producto',
};

const usersTypeToSpanish = {
  totalUsers: 'Usuarios totales',
  activeUsers: 'Usuarios activos',
  lostUsers: 'Usuarios perdidos',
  oldUsers: 'Usuarios antiguos',
  newUsers: 'Usuarios nuevos',
};

export const translateDay = (day, language) => {
  if (language === 'ES') return dayToSpanish[day];
  return '';
};

export const translatePromotionalCodeType = (type, language) => {
  if (language === 'ES') return promotionalCodeTypeToSpanish[type];
  return '';
};

export const translateUsersType = (type, language) => {
  if (language === 'ES') return usersTypeToSpanish[type];
  return '';
};
