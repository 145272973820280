import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_SALES_DATA } from '../gql/analytics';
import { SalesPerPeriod, SalesPerPeriodPerElement } from '../components/charts/sales';
import SalesRanking from '../components/charts/SalesRanking';
import ErrorPage from './ErrorPage';
import { TimePeriodSelect } from '../components/inputs';
import { MainContext } from '../context/MainContext';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2% 5% 2% 0',
  },
  header: {
    marginRight: '3rem',
  },
  rankingContainer: {
    maxHeight: 520,
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      maxHeight: 250,
    },
  },
}));

const SalesAnalytics = () => {
  const classes = useStyles();
  const { store } = useContext(MainContext);
  const { data, error } = useQuery(GET_SALES_DATA, {
    variables: { storeId: store.id },
  });
  const [totalSalesByPeriod, setTotalSalesByPeriod] = useState([]);
  const [averageSalesByPeriod, setAverageSalesByPeriod] = useState([]);
  const [salesByItemByPeriod, setSalesByItemByPeriod] = useState({});
  const [salesByCategoryByPeriod, setSalesByCategoryByPeriod] = useState({});
  const [salesByItem, setSalesByItem] = useState([]);
  const [salesByCategory, setSalesByCategory] = useState([]);
  // start with daily period
  const [periods, setPeriods] = useState({
    totalSales: 'daily',
    averageSales: 'daily',
    itemsSales: 'daily',
    categoriesSales: 'daily',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      const {
        getSalesData: {
          totalSalesByPeriod: newTotalSalesByPeriod,
          averageSalesByPeriod: newAverageSalesByPeriod,
          salesByItemByPeriod: newSalesByItemByPeriod,
          salesByCategoryByPeriod: newSalesByCategoryByPeriod,
          salesByItem: newSalesByItem,
          salesByCategory: newSalesByCategory,
        },
      } = data;
      setTotalSalesByPeriod(newTotalSalesByPeriod);
      setAverageSalesByPeriod(newAverageSalesByPeriod);
      setSalesByItemByPeriod(newSalesByItemByPeriod);
      setSalesByCategoryByPeriod(newSalesByCategoryByPeriod);
      setSalesByItem(newSalesByItem);
      setSalesByCategory(newSalesByCategory);
      setLoading(false);
    }
  }, [data]);

  const changePeriod = (event, type) => {
    const { value } = event.target;
    setPeriods((oldPeriods) => ({ ...oldPeriods, [type]: value }));
  };

  if (loading) return <LinearProgress />;
  if (error) return <ErrorPage />;

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12}>
        <Grid container>
          <Typography gutterBottom variant="h5" className={classes.header}>Ventas totales</Typography>
          <TimePeriodSelect
            currentSelection={periods.totalSales}
            onChange={(e) => changePeriod(e, 'totalSales')}
          />
        </Grid>
        <SalesPerPeriod sales={totalSalesByPeriod[periods.totalSales]} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Typography gutterBottom variant="h5" className={classes.header}>Ventas Promedio</Typography>
          <TimePeriodSelect
            currentSelection={periods.averageSales}
            onChange={(e) => changePeriod(e, 'averageSales')}
          />
        </Grid>
        <SalesPerPeriod sales={averageSalesByPeriod[periods.averageSales]} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Typography gutterBottom variant="h5" className={classes.header}>Venta total por producto</Typography>
          <TimePeriodSelect
            currentSelection={periods.itemsSales}
            onChange={(e) => changePeriod(e, 'itemsSales')}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <SalesPerPeriodPerElement
              labels={Object.keys(totalSalesByPeriod[periods.itemsSales])}
              dataset={salesByItemByPeriod[periods.itemsSales]}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.rankingContainer}>
            <SalesRanking values={salesByItem} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Typography gutterBottom variant="h5" className={classes.header}>Venta total por categoría</Typography>
          <TimePeriodSelect
            currentSelection={periods.categoriesSales}
            onChange={(e) => changePeriod(e, 'categoriesSales')}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <SalesPerPeriodPerElement
              labels={Object.keys(totalSalesByPeriod[periods.categoriesSales])}
              dataset={salesByCategoryByPeriod[periods.categoriesSales]}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.rankingContainer}>
            <SalesRanking values={salesByCategory} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesAnalytics;
