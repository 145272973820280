import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MainContextProvider } from './context/MainContext';
import { host } from './config';
import 'moment/locale/es';

const uploadLink = createUploadLink({
  uri: `${host}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user ? user.jwt : null;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

let theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        padding: '10px 14px !important',
      },
      input: {
        padding: '0 !important',
      },
    },
    MuiButton: {
      text: {
        textTransform: 'none',
      },
      contained: {
        height: '3rem',
      },
    },
  },
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MainContextProvider>
          <App />
        </MainContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
